@use "settings/s-colors" as colors;

.u-scrollbar {
  .os-scrollbar {
    --os-handle-bg: #{colors.$primary-200};
    --os-handle-bg-hover: #{colors.$primary-200};
    --os-handle-bg-active: #{colors.$primary-300};
    --os-handle-border-radius: 0px;
    --os-size: 32px;
    --os-track-bg: #{colors.$primary-100};
    --os-track-bg-hover: #{colors.$primary-100};
    --os-track-bg-active: #{colors.$primary-100};
    --os-track-border-radius: 0px;
    --os-padding-perpendicular: 12px;
    --os-padding-axis: 16px;

    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
}
