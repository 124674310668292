@use "settings/s-colors" as colors;

.widget_media_image {
  a {
    display: block;
    overflow: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);

    img {
      transition-property: opacity, transform;
      will-change: transform, opacity;
    }

    &:hover {
      img {
        transform: scale(1.03);
      }
    }
  }
}
