@use "settings/s-shadows" as shadows;
@use "settings/s-colors" as colors;

.i-tooltip__content {
  max-width: 122px;
  background-color: colors.$white;
  filter: drop-shadow(shadows.$tooltip-drop-shadow);

  &::before {
    content: "";
    position: absolute;
    top: -4px;
    left: 18px;
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background-color: colors.$white;
  }
}
