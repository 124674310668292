@use "settings/s-colors" as colors;

.w-categories__item-link {
  min-height: 56px;
  text-decoration: none;
  color: var(--color);
  background-color: var(--background-color);
  border: 1px solid colors.$primary-100;
  transition: 0.3s ease;
  transition-property: color, background-color;

  &:hover {
    --background-color: #{colors.$primary-100};
  }
}

.w-categories__item {
  --background-color: transparent;
  --color: #{colors.$primary};

  &.--active {
    --background-color: transparent;
    --color: #{colors.$conversion-500};

    .w-categories__item-link {
      &:hover,
      &:focus {
        --color: #{colors.$conversion-500};

        color: var(--color);
      }
    }
  }
}
