@use "settings/s-colors" as colors;

.g-usps {
  display: grid;
  gap: 32px;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 2fr);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(5, 1fr);
  }
}
