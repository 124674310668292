@use "settings/s-colors" as colors;

.i-document {
  --border-color: #{colors.$primary-200};
  --color: #{colors.$primary};

  min-height: 88px;
  height: 100%;
  box-shadow: 0 0 0 1px var(--border-color);
  background-color: colors.$white;
  transition: box-shadow 0.3s ease;

  @media (hover: hover) {
    &:hover {
      --border-color: #{colors.$conversion-300};
      --color: #{colors.$conversion-500};
    }
  }
}

.i-document__title {
  color: var(--color);
  transition: color 0.3s ease;
}

.i-document__metadata {
  padding-block: 4px;
}
