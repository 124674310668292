@use "settings/s-colors" as colors;

.s-text-media__grid {
  display: flex;
  flex-direction: column;
  gap: 64px;

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 396px 1fr;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 448px 1fr;
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: 568px 1fr;
  }

  @include media-breakpoint-down(lg) {
    gap: 48px;
  }

  @include media-breakpoint-down(md) {
    gap: 24px;
  }
}

.s-text-media__col.--content-col {
  order: var(--content-order);
  padding-left: var(--content-offset-left);
  padding-right: var(--content-offset-right);
}

.s-text-media {
  --content-offset-left: 0;
  --content-offset-right: 0;
  --content-order: 1;

  &.--reverse {
    .s-text-media__grid {
      @include media-breakpoint-up(lg) {
        --content-order: -1;

        grid-template-columns: 1fr 396px;
      }

      @include media-breakpoint-up(xl) {
        grid-template-columns: 1fr 448px;
      }

      @include media-breakpoint-up(xxl) {
        grid-template-columns: 1fr 568px;
      }
    }
  }

  &.--product {
    .s-text-media__grid {
      @include media-breakpoint-up(xxl) {
        grid-template-columns: 656px 1fr;
      }

      @include media-breakpoint-down(xxl) {
        gap: 48px;
      }

      @include media-breakpoint-only(lg) {
        gap: 32px;
      }
    }

    .s-text-media__col.--media-col {
      @include media-breakpoint-down(lg) {
        max-width: 656px;
        margin-inline: auto;
      }
    }

    .s-text-media__col.--content-col {
      justify-content: flex-start;
      padding-inline: 8px;

      @include media-breakpoint-up(lg) {
        padding-inline: 16px;
      }

      @include media-breakpoint-up(xxl) {
        padding-inline: 32px;
      }
    }

    .s-text-media__meta-label {
      min-width: 88px;
    }

    .s-text-media__specs {
      &::before {
        content: "";
        position: absolute;
        top: -24px;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 1px;
        background-color: colors.$primary-100;
        pointer-events: none;
      }
    }
  }
}
