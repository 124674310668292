.i-media {
  &.--video {
    cursor: pointer;
  }

  &.--product {
    .i-media__tag.i-tag {
      top: 8px;
      right: 8px;
      pointer-events: none;
    }
  }
}
