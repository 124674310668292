.g-testimonials {
  gap: 80px;

  @include media-breakpoint-down(xxl) {
    gap: 52px;
  }

  @include media-breakpoint-down(lg) {
    gap: 32px;
  }
}
