@use "settings/s-colors" as colors;

.f-range-slider {
  --height: 4px;
  --thumb-size: 16px;
  --thumb-offset: 8px;
  --color: #{colors.$primary};
  --background: #{colors.$primary-200};
  --gradient-from: calc(var(--background-from) - var(--thumb-offset));
  --gradient-to: calc(var(--background-to) + var(--thumb-offset));
  --opacity: 1;
  --pointer-events: all;

  gap: 36px;
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  transition: opacity 0.3s ease;

  &.--disabled {
    --opacity: 0.4;
    --pointer-events: none;

    user-select: none;
  }
}

.f-range-slider__ranges {
  height: var(--height);
}

.f-range-slider__range-wrap {
  &::before {
    content: "";
    position: absolute;
    height: var(--height);
    inset: 0;
    background-color: var(--background);
    z-index: -1;
    border-radius: var(--height);
  }
}

.f-range-slider__range {
  appearance: none;
  outline: none;
  pointer-events: none;
  inset: 0 var(--thumb-offset);
  height: var(--height);
  width: calc(100% - var(--thumb-size));
  position: absolute;
  background: linear-gradient(
    90deg,
    transparent 0%,
    transparent var(--gradient-from),
    var(--color) var(--gradient-from),
    var(--color) var(--gradient-to),
    transparent var(--gradient-to),
    transparent 100%
  );

  &::-webkit-slider-thumb,
  &::-moz-range-thumb {
    appearance: none;
    pointer-events: var(--pointer-events);
    width: var(--thumb-size);
    height: var(--thumb-size);
    background-color: colors.$conversion-300;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    transform: translateX(calc(var(--thumb-offset) * var(--side)));
    transition: 0.2s ease background-color;
    z-index: 2;
  }

  &.--from {
    --side: -1;
  }

  &.--to {
    --side: 1;
  }
}

.f-range-slider__label.--from,
.f-range-slider__label.--to {
  bottom: 14px;
  white-space: nowrap;
}

.f-range-slider__inputs {
  display: none;
}
