@use "settings/s-colors" as colors;

.i-banner__header {
  --max-width: 100%;

  max-width: var(--max-width);

  @include media-breakpoint-up(xl) {
    --max-width: 732px;
  }

  @include media-breakpoint-up(xxl) {
    --max-width: 975px;
  }
}

.i-banner__buttons {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
