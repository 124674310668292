.i-testimonial {
  --blend-mode: darken;
  --grayscale: 1;
  --width: 136px;
  --height: 48px;

  width: var(--width);
  max-width: var(--width);
  height: var(--height);
  justify-self: center;

  & .i-testimonial__image {
    mix-blend-mode: var(--blend-mode);
    filter: grayscale(var(--grayscale));
    transition: 0.3s ease;
    transition-property: mix-blend-mode, filter;
    will-change: mix-blend-mode, filter;
  }

  &:hover {
    --blend-mode: normal;
    --grayscale: 0;
  }
}
