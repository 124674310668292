@use "settings/s-colors" as colors;

.wp-aside-widgets ul {
  display: flex;
  flex-direction: column;
}

.wp-aside-widgets .widget {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include media-breakpoint-down(lg) {
    --content-display: none;
    --svg-rotate: 0deg;

    ul,
    form {
      display: var(--content-display);
    }

    &.--open {
      --content-display: flex;
      --svg-rotate: 180deg;
    }
  }
}

.wp-aside-widgets .widgettitle {
  --size: 22px;

  @include media-breakpoint-down(md) {
    --size: 20px;
  }

  display: block;
  font-size: var(--size);
  line-height: 24px;
  position: relative;
}

.wp-aside-widgets .wp-caption {
  max-width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.wp-aside-widgets li {
  --color: #{colors.$primary};

  display: block;

  > a {
    display: flex;
    align-items: center;
    min-height: 56px;
    padding-block: 16px;
    padding-left: 16px;
    padding-right: 16px;
    border: 1px solid colors.$primary-100;
    background-color: transparent;
    color: var(--color);
    text-decoration-color: transparent;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      text-decoration-color 0.3s ease;

    &:hover {
      text-decoration-color: transparent;
      background-color: colors.$primary-100;
    }
  }
}

.widget__dropdown-btn {
  --size: 24px;

  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    rotate: var(--svg-rotate);
    transition: rotate 0.3s ease;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.wp-aside-widgets .sub-menu__button {
  --size: 40px;

  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    rotate: var(--svg-rotate);
    transition: rotate 0.3s ease;
  }
}
