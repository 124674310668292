@use "settings/s-colors.scss" as colors;

.s-post-detail__meta {
  @include media-breakpoint-up(lg) {
    column-gap: 80px;
  }
}

.s-post-detail__share-links {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.s-post-detail__share {
  &::before {
    top: 0;
    transform: translateY(-50%);
  }

  &::after {
    bottom: 0;
    transform: translateY(50%);
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: colors.$primary-200;
  }
}
