@use "settings/s-colors" as colors;

.base-heading {
  &.--decoration-after {
    width: 100%;
    display: inline-flex;
    gap: 16px;

    .base-heading__decoration-after {
      max-width: 100%;
      height: 1px;
    }
  }
}
