@use "settings/s-colors.scss" as colors;

.i-post {
  &.--default {
    height: 100%;
  }

  @include media-breakpoint-down(lg) {
    max-width: 448px;
    margin-inline: auto;
  }
}

.i-post__heading-link {
  color: colors.$primary;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      color: colors.$conversion-500;
    }
  }
}

.i-post__arrow-link {
  text-transform: uppercase;
}

.i-post__image-col,
.i-post__content-col {
  height: fit-content;
}
