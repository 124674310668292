@use "settings/s-colors.scss" as colors;

.main-footer__columns {
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.main-footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 1568px;
  height: 1px;
  background-color: colors.$primary-200;
}

.main-footer__top {
  padding-block: 80px;

  @include media-breakpoint-down(sm) {
    padding-block: 40px;
  }
}

.main-footer__strip {
  background: colors.$secondary-400;
  height: 16px;
  width: 100%;
  max-width: 100%;
}
