@use "settings/s-colors" as colors;

.g-steps {
  row-gap: 72px;

  &.--vertical {
    max-width: 686px;
    margin-inline: auto;
  }
}
