@use "settings/s-colors" as colors;

.i-contact-card__body {
  border: 1px solid colors.$primary-100;
  border-top: none;
}

.i-contact-card__map-link {
  --scale: 1;
  --transition: transform 0.3s ease-in-out;

  width: 48px;
  right: 16px;
  bottom: 16px;
  text-decoration: none;
  outline: none;
  transform: scale(var(--scale));
  transform-origin: bottom right;
  transition: var(--transition);

  svg {
    transition: var(--transition);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid colors.$primary;
    border-radius: 50%;
    pointer-events: none;
    z-index: 1;
  }

  @media (hover: hover) {
    &:hover {
      --scale: 1.67;

      svg {
        transform: scale(0.67);
      }
    }
  }
}
