@use "settings/s-colors" as colors;

.i-product {
  border: 1px solid colors.$primary-200;
}

.i-product__content {
  &::before {
    content: "";
    position: absolute;
    top: -16px;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: colors.$primary-100;
    pointer-events: none;
  }
}

.i-product__image .img {
  --border-color: transparent;

  &::before {
    z-index: 1;
  }

  img {
    position: relative;
    z-index: 2;
  }

  border: 1px solid var(--border-color);
  transition: border-color 0.3s ease;

  @media (hover: hover) {
    &:hover {
      --border-color: #{colors.$conversion-300};
    }
  }
}

.i-product__tag.i-tag {
  top: 8px;
  right: 8px;
  pointer-events: none;
}

.i-product__specs {
  gap: 6px;
}

.i-product__image-placeholder {
  aspect-ratio: 1 / 1;
  opacity: 0.5;
}
