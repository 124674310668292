@use "settings/s-colors" as colors;

.g-products-slider__button {
  --offset: calc(50% - 4px);

  pointer-events: auto;

  &.btn.--outline {
    @media (width <= 359px) {
      --min-height: 32px;
      --offset: calc(50% - 12px);

      .icon {
        --size: 16px;
      }
    }
  }

  &.--prev {
    transform: translateX(calc(-1 * var(--offset)));
  }

  &.--next {
    transform: translateX(var(--offset));
  }
}

.g-products-slider {
  &.swiper {
    --wrapper-gap: 32px;
    --slide-max-width: 328px;

    display: flex;
    align-items: center;
    overflow: initial;

    &.swiper-initialized {
      --wrapper-gap: 0px;
      --slide-max-width: auto;
    }
  }

  .swiper-wrapper {
    align-items: center;
    gap: var(--wrapper-gap);
  }

  .swiper-slide {
    flex-shrink: 0;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    max-width: var(--slide-max-width);
  }

  .swiper-slide-visible {
    opacity: 1;
    pointer-events: auto;
  }
}

.g-products-slider__buttons {
  pointer-events: none;

  .swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
}
