@use "settings/s-colors" as colors;

.i-hero-slide__body {
  --radius: 8px;
  --padding-y: 40px;
  --padding-x: 80px;

  max-width: 688px;
  width: 100%;
  min-height: 428px;
  height: auto;
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);

  @include media-breakpoint-down(xxl) {
    max-width: 600px;
  }

  @include media-breakpoint-down(xl) {
    padding: 24px;
  }

  @include media-breakpoint-down(lg) {
    position: static;
    transform: translate3d(0, 0, 0);
    left: auto;
    right: auto;
    max-width: 100%;
    height: 100%;
    gap: 8px;
  }

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }
}

.i-hero-slide {
  --offset: 0;

  height: 540px;

  &.--left {
    .i-hero-slide__body {
      top: 50%;
      left: var(--offset);
      transform: translateY(-50%);

      @include media-breakpoint-down(xl) {
        --offset: 24px;
      }

      @include media-breakpoint-down(lg) {
        text-align: center;
        transform: translateY(0);
      }
    }
  }

  &.--right {
    .i-hero-slide__body {
      top: 50%;
      right: var(--offset);
      transform: translateY(-50%);
      margin-left: auto;

      @include media-breakpoint-down(xl) {
        right: 24px;
      }

      @include media-breakpoint-down(lg) {
        text-align: center;
        transform: translateY(0);
      }
    }
  }

  &.--center {
    .i-hero-slide__body {
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include media-breakpoint-down(lg) {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 16px;
  }
}

.i-hero-slide__footer {
  margin-top: 64px;
}

.i-hero-slide__heading {
  color: colors.$secondary-400;
  margin-bottom: 24px;

  @include media-breakpoint-down(xxl) {
    font-size: 44px;
    line-height: 48px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 40px;
    line-height: 42px;
  }

  @include media-breakpoint-down(lg) {
    margin-bottom: 8px;
  }
}

.i-hero-slide__description {
  font-weight: 500;

  @include media-breakpoint-down(xxl) {
    font-size: 24px;
    line-height: 28px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 20px;
    line-height: 24px;
  }

  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }
}

.i-hero-slide__image {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, colors.$primary-500 0%, colors.$primary-500 25%, rgb(99 102 105 / 0%) 100%);
    opacity: 0.7;
    mix-blend-mode: multiply;
  }
}
