.g-buttons {
  --spacing: 48px;

  margin-top: var(--spacing);

  &.--spacing-none {
    --spacing: 0;
  }

  &.--spacing-lg {
    --spacing: 64px;

    @include media-breakpoint-down(md) {
      --spacing: 32px;
    }
  }

  &.--spacing-xl {
    --spacing: 80px;

    @include media-breakpoint-down(md) {
      --spacing: 40px;
    }
  }
}
