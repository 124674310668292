@use "settings/s-colors.scss" as colors;

.i-tag {
  --font-color: #{colors.$primary};
  --background-color: #{colors.$primary-100};

  background-color: var(--background-color);
  color: var(--font-color);
  border-radius: 0;
  border: 1px solid var(--background-color);

  &.--outline {
    --background-color: #{colors.$white};

    border-color: colors.$primary-200;

    &.--hoverable:hover {
      --background-color: #{colors.$white};
    }
  }

  &.--blank {
    --background-color: transparent;

    padding: 0;
    min-height: auto;
  }

  &.--product {
    --font-color: #{colors.$white};
    --background-color: #{colors.$label};
  }

  &.--code {
    --font-color: #{colors.$secondary-600};
    --background-color: #{colors.$primary-100};
    --padding-x: 16px;

    font-weight: 700;
  }

  &.--radius {
    border-radius: 100px;
  }

  &.--icon-before {
    .i-tag__icon {
      order: -1;
    }
  }

  &.--icon-only {
    width: var(--min-height);
    height: var(--min-height);
    min-height: auto;
    padding: 0;

    .i-tag__title {
      display: none;
    }
  }
}

a.i-tag,
.i-tag.--hoverable {
  text-decoration: none;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;
  cursor: pointer;

  &:hover {
    --background-color: #{colors.$primary-200};
  }
}

a.i-tag.--blank {
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;

  &:hover {
    border-color: transparent;
    text-decoration-color: var(--font-color);
  }
}
