@use "settings/s-colors" as colors;

@media print {
  @page {
    size: auto;
    margin: 10mm 12mm;
  }

  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  body {
    padding: 0;
    margin: 0;
  }

  .pdf-header {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
      width: 100%;
      max-width: 100%;
      height: 1px;
      background-color: colors.$primary-200;
    }
  }

  .pdf-footer__url {
    text-decoration-color: colors.$primary-500;
  }

  .pdf-footer__right-side {
    gap: 32px;
  }

  .s-pdf-variant__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px !important;
  }

  .s-pdf-variant__description {
    gap: 32px;
  }

  .s-pdf-variant__specs {
    &::before {
      content: "";
      position: absolute;
      top: -12px;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 1px;
      background-color: colors.$primary-200;
      pointer-events: none;
    }
  }

  .s-pdf-variant__code-number {
    font-weight: 600;
  }

  .pdf-header__body .img {
    max-width: 124px !important;
  }

  .pdf-footer__body .img {
    max-width: 96px !important;
  }

  .i-pdf-variant-parameter {
    font-size: 7.5pt !important;
    line-height: 10.5pt !important;
    padding-inline: 12px;
  }

  .i-pdf-variant-parameter:nth-child(odd) {
    background-color: colors.$primary-200;
  }

  .i-pdf-variant-parameter__title {
    min-width: 120px;
  }

  .s-pdf-variant {
    margin-inline: 8px !important;
  }

  .s-pdf-variant__photometrics {
    margin-top: 16px !important;
    margin-bottom: 8px !important;

    a {
      font-size: 7.5pt !important;
    }
  }

  .s-pdf-variant__content {
    gap: 0 !important;
  }

  .s-pdf-variant__meta-label {
    min-width: 72px !important;
  }

  .s-pdf-variant__code {
    transform: translateY(-8px) !important;
  }

  .s-pdf-variant__code-label.u-fz-md {
    line-height: 18pt !important;
  }

  .pdf-header-column__title {
    font-weight: 700;
  }

  .u-fz-xl {
    font-size: 9pt !important;
    line-height: 12pt !important;
  }

  .u-fz-lg {
    font-size: 7.5pt !important;
  }

  .u-fz-md {
    font-size: 7.5pt !important;
    line-height: 10.5pt !important;
  }

  .u-fz-xs {
    font-size: 6pt !important;
  }

  .u-h3 {
    font-size: 12pt !important;
  }

  .u-h5 {
    font-size: 7.5pt !important;
    line-height: 9pt !important;
  }

  .i-pdf-spec {
    width: calc((100% - 2 * 2.5rem) / 3);
  }

  .i-pdf-spec__content.entry-content {
    font-size: 6pt !important;
    line-height: 7.5pt !important;
  }

  .entry-content {
    font-size: 7.5pt !important;
    line-height: 10.5pt !important;

    li {
      padding-left: 24px !important;
      margin-bottom: 4px !important;

      &::before {
        width: 4px !important;
        height: 4px !important;
        top: 4px !important;
      }
    }
  }
}
