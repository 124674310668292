@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.c-language-select {
  --dropdown-opacity: 0;
  --dropdown-pointer-events: none;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -32px;
    transform: translateX(-50%);
    width: 1px;
    height: 100%;
    background-color: colors.$primary-200;
  }

  &:hover {
    --dropdown-opacity: 1;
    --dropdown-pointer-events: auto;
  }
}

.c-language-select__drowpdown {
  background-color: colors.$white;
  border: 1px solid colors.$primary-200;
  transition: opacity 0.3s ease;
  top: 100%;
  left: 0;
  opacity: var(--dropdown-opacity);
  pointer-events: var(--dropdown-pointer-events);
  box-shadow: shadows.$shadow;
}

.c-language-select__active {
  min-height: 40px;
}

.c-language-select__dropdown-link {
  color: colors.$primary;
  text-decoration: none;

  &:hover {
    color: colors.$conversion-500;
  }
}
