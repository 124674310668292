@use "settings/s-colors.scss" as colors;

.i-breadcrumb {
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -16px;
    transform: translate(0, -50%);
    width: 1px;
    height: 15px;
    background-color: rgb(colors.$primary-200, 0.7);
  }

  &.--homepage {
    &::before {
      display: none;
    }

    .i-breadcrumb__divider {
      display: none;
    }
  }
}
