@use "settings/s-colors" as colors;

.f-checkbox__visual {
  width: var(--size);
  height: var(--size);
  border: 1px solid var(--border-color);
  background-color: colors.$white;
  transition:
    border 0.3s ease,
    background-color 0.3s ease;
  border-radius: var(--radius);

  svg {
    opacity: 0;
    transform: scale(0.2);
    transition: opacity 0.3s ease;
    transition-property: opacity, transform;
  }

  &:hover {
    --border-color: #{colors.$conversion-500};
  }
}

.f-checkbox__input {
  left: calc(var(--size) / 2);
  top: calc(var(--size) / 2);
  transform: translateX(-50%);
  pointer-events: none;
  opacity: 0;

  &:checked ~ .f-checkbox__visual {
    --border-color: #{colors.$conversion-300};

    background-color: var(--color);

    svg {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.f-checkbox__title {
  a {
    color: var(--color);
    font-weight: 600;
  }
}

.f-checkbox {
  --border-color: #{colors.$primary};
  --color: #{colors.$conversion-300};
  --radius: 0px;
  --size: 24px;

  cursor: pointer;

  &.--disabled {
    --color: #{colors.$primary-300};
    --border-color: #{colors.$primary-200};

    pointer-events: none;

    .f-checkbox__input {
      &:checked ~ .f-checkbox__visual {
        --border-color: #{colors.$primary-200};

        background-color: var(--border-color);
      }

      &:not(:checked) ~ .f-checkbox__visual {
        background-color: colors.$white;
      }
    }

    .f-checkbox__title {
      color: var(--color);
    }
  }

  &.--radio {
    --radius: calc(var(--size) / 2);
  }
}
