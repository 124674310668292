@use "settings/s-colors" as colors;

.s-variant-intro__grid {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 396px 1fr;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 448px 1fr;
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: 478px 1fr;
  }
}

.s-variant-intro__meta-label {
  min-width: 88px;
}

.s-variant-intro__specs {
  &::before {
    content: "";
    position: absolute;
    top: -24px;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: colors.$primary-100;
    pointer-events: none;
  }
}

.s-variant-intro__gallery-slider {
  @include media-breakpoint-down(lg) {
    max-width: 396px;
    margin-inline: auto;
  }

  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}

.s-variant-intro__content-header {
  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;

    .--js-pdf-button {
      margin-left: auto;
    }
  }

  @include media-breakpoint-only(lg) {
    flex-direction: column;
    align-items: flex-start;

    .--js-pdf-button {
      margin-left: auto;
    }
  }
}
