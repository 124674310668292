.u-fz-xs {
  --size: 12px;

  font-size: var(--size);
  line-height: calc(var(--size) + 4px);
}

.u-fz-sm {
  --size: 14px;

  font-size: var(--size);
  line-height: calc(var(--size) + 2px);
}

.u-fz-md {
  --size: 16px;

  font-size: var(--size);
  line-height: calc(var(--size) + 8px);
}

.u-fz-lg {
  --size: 18px;

  font-size: var(--size);
  line-height: calc(var(--size) + 6px);
}

.u-fz-xl {
  --size: 20px;

  font-size: var(--size);
  line-height: calc(var(--size) + 8px);
}
