@use "settings/s-colors" as colors;

.g-gallery-slider__wrapper {
  .swiper-slide {
    --border-color: #{colors.$primary-200};

    opacity: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid var(--border-color);
      z-index: 2;
      pointer-events: none;
      transition: border 0.3s ease;
    }

    &:hover {
      --border-color: #{colors.$primary};
    }

    &.--active {
      --border-color: #{colors.$conversion-300};
    }
  }
}

div.fancybox__nav {
  button.carousel__button {
    --font-color: #{colors.$conversion-500};
    --border-color: #{colors.$conversion-500};

    background-color: colors.$white;
    border: 1px solid var(--border-color);
    border-radius: 0;
    overflow: hidden;
    transition: 0.6s ease;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 100%;
      pointer-events: none;
      background-color: colors.$conversion-300;
      z-index: -1;
      transform-origin: 0 0;
      transform: skewX(45deg) translateX(100%);
      transition: transform 0.6s;
    }

    @media (hover: hover) {
      &:hover {
        --font-color: #{colors.$white};
        --border-color: #{colors.$conversion-300};

        &::after {
          transform: skewX(45deg) translateX(-50%);
        }
      }
    }

    svg {
      display: none;
    }

    &::before {
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      background-image: url("../images/svg/chevron-right-fancybox.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px;
      user-select: none;
      z-index: 1;
      transition:
        background-image 0.3s ease,
        transform 0.3s ease;
    }

    &.is-next {
      @media (hover: hover) {
        &:hover {
          &::before {
            transform: translateX(5px);
            background-image: url("../images/svg/chevron-right-fancybox-hover.svg");
          }
        }
      }
    }

    &.is-prev {
      &::before {
        transform: rotate(-180deg);
      }

      @media (hover: hover) {
        &:hover {
          &::before {
            transform: translateX(-5px) rotate(-180deg);
            background-image: url("../images/svg/chevron-right-fancybox-hover.svg");
          }
        }
      }
    }
  }
}

div.fancybox__backdrop {
  background-color: #002c5b66;
}

div.fancybox__thumb {
  border-radius: 0;

  &::after {
    border-width: 3px !important;
    border-color: colors.$conversion-300 !important;
    border-radius: 0 !important;
  }
}
