@use "settings/s-colors" as colors;

.f-filtration__header {
  min-height: var(--min-height);
  background-color: var(--bg-header);
  border-bottom: 2px solid colors.$primary-200;
  transition: background-color 0.3s ease;
}

.f-filtration__body {
  display: var(--display);
}

.f-filtration__body-inner {
  border: 1px solid colors.$primary-200;
  border-top: 0;
}

.f-filtration__icon {
  path {
    fill: colors.$primary;
  }
}

.f-filtration {
  --min-height: 72px;
  --bg-header: #{colors.$white};
  --display: none;

  &.--open {
    --bg-header: #{colors.$primary-100};
    --display: grid;
  }

  &.--default {
    .f-filtration__form-list .f-checkbox-select__body {
      position: absolute;
    }

    .f-filtration__title {
      @include media-breakpoint-down(sm) {
        margin-right: auto;
      }
    }
  }

  &.--inline {
    --min-height: 56px;
    --bg-header: #{colors.$primary-100};
    --display: flex;

    .f-filtration__header {
      @include media-breakpoint-down(sm) {
        flex-direction: row;
      }
    }

    .f-filtration__form-list {
      flex-direction: column;

      @include media-breakpoint-down(xl) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .f-checkbox-select__body {
          position: absolute;
        }
      }

      @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-breakpoint-down(sm) {
        display: flex;

        .f-checkbox-select__body {
          position: relative;
        }
      }
    }
  }
}
