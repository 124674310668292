@use "settings/s-colors" as colors;

.i-menu {
  --padding-left: 16px;
  --padding-right: 16px;
  --border-color: transparent;

  &.--level-1 {
    &.--current {
      --border-color: #{colors.$conversion-500};
    }
  }

  @include media-breakpoint-between(lg, xxl) {
    --padding-left: 12px;
    --padding-right: 12px;

    &:last-child {
      .i-menu__link::after {
        display: none;
      }
    }
  }
}

.i-menu__link {
  padding-block: 12px;
  min-height: 56px;
  color: colors.$primary;
  border-bottom: 4px solid var(--border-color);
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  text-decoration: none;
  z-index: 1;
  transition: border 0.3s ease;
}

// Desktop
@include media-breakpoint-up(lg) {
  .i-menu__link {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      background-color: colors.$primary-200;
      height: 24px;
      width: 1px;
    }

    &:hover {
      --border-color: #{colors.$conversion-500};
    }
  }
}
