@use "settings/s-colors.scss" as colors;

.u-color-primary {
  color: colors.$primary;
}

.u-color-primary-300 {
  color: colors.$primary-300;
}

.u-color-primary-500 {
  color: colors.$primary-500;
}

.u-color-secondary {
  color: colors.$secondary;
}

.u-color-secondary-400 {
  color: colors.$secondary-400;
}

.u-color-conversion-500 {
  color: colors.$conversion-500;
}

.u-color-white {
  color: colors.$white;
}
