@use "settings/s-colors.scss" as colors;

.link {
  &.--black {
    --color: #{colors.$black};

    color: var(--color);
    text-decoration-color: var(--color);

    &:hover,
    &:focus {
      color: var(--color);
      text-decoration-color: transparent;
    }
  }

  &.--primary-to-conversion {
    --color: #{colors.$primary};

    color: var(--color);
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      --color: #{colors.$conversion-500};

      color: var(--color);
      text-decoration-color: transparent;
    }
  }

  &.--primary {
    --color: #{colors.$conversion-500};

    color: var(--color);
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      --color: #{colors.$primary};

      color: var(--color);
      text-decoration-color: var(--color);
      text-decoration-thickness: 2px;

      path {
        stroke: var(--color-hover);
      }
    }
  }

  &.--primary-only {
    --color: #{colors.$primary};

    color: var(--color);
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      --color: #{colors.$primary};

      color: var(--color);
      text-decoration-color: transparent;
    }
  }
}
