h6, h5, h4, h3, h2, h1 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .25rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (width >= 75em) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.5rem;
  }
}

@media (width >= 36em) {
  .container {
    max-width: 33.75rem;
  }
}

@media (width >= 48em) {
  .container {
    max-width: 45rem;
  }
}

@media (width >= 62em) {
  .container {
    max-width: 60rem;
  }
}

@media (width >= 75em) {
  .container {
    max-width: 71.25rem;
  }
}

@media (width >= 93.75em) {
  .container {
    max-width: 91.25rem;
  }
}

@media (width >= 36em) {
  .col-sm-6 {
    flex: none;
    width: 50%;
  }
}

@media (width >= 48em) {
  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }
}

@media (width >= 62em) {
  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }
}

@media (width >= 75em) {
  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }
}

@media (width >= 93.75em) {
  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }
}

@media (width >= 36em) {
  .flex-sm-row {
    flex-direction: row;
  }

  .align-items-sm-center {
    align-items: center;
  }

  .ms-sm-auto {
    margin-left: auto;
  }
}

@media (width >= 48em) {
  .flex-md-row {
    flex-direction: row;
  }

  .justify-content-md-end {
    justify-content: flex-end;
  }

  .align-items-md-center {
    align-items: center;
  }

  .mt-md-3 {
    margin-top: 1.5rem;
  }

  .px-md-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .py-md-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .gap-md-3 {
    gap: 1.5rem;
  }

  .gap-md-4 {
    gap: 2rem;
  }

  .gap-md-5 {
    gap: 2.5rem;
  }

  .row-gap-md-5 {
    row-gap: 2.5rem;
  }
}

@media (width >= 62em) {
  .d-lg-block {
    display: block;
  }

  .d-lg-flex {
    display: flex;
  }

  .d-lg-none {
    display: none;
  }

  .flex-lg-row {
    flex-direction: row;
  }

  .justify-content-lg-between {
    justify-content: space-between;
  }

  .align-items-lg-center {
    align-items: center;
  }

  .px-lg-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .gap-lg-2 {
    gap: 1rem;
  }
}

@media (width >= 75em) {
  .d-xl-flex {
    display: flex;
  }

  .mb-xl-3 {
    margin-bottom: 1.5rem;
  }

  .ms-xl-4 {
    margin-left: 2rem;
  }
}

@media (width >= 93.75em) {
  .p-xxl-5 {
    padding: 2.5rem;
  }

  .px-xxl-5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .pe-xxl-3 {
    padding-right: 1.5rem;
  }

  .gap-xxl-2 {
    gap: 1rem;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
  margin: .5rem 0;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .25rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (width >= 75em) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (width >= 75em) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (width >= 75em) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (width >= 75em) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: .875em;
}

mark {
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--bs-secondary-color);
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -.125rem;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

iframe {
  border: 0;
}

[hidden] {
  display: none !important;
}

.container, .container-fluid {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 36em) {
  .container {
    max-width: 33.75rem;
  }
}

@media (width >= 48em) {
  .container {
    max-width: 45rem;
  }
}

@media (width >= 62em) {
  .container {
    max-width: 60rem;
  }
}

@media (width >= 75em) {
  .container {
    max-width: 71.25rem;
  }
}

@media (width >= 93.75em) {
  .container {
    max-width: 91.25rem;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 36rem;
  --bs-breakpoint-md: 48rem;
  --bs-breakpoint-lg: 62rem;
  --bs-breakpoint-xl: 75rem;
  --bs-breakpoint-xxl: 93.75rem;
  --bs-breakpoint-xxxl: 120rem;
}

.row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.gx-0 {
  --bs-gutter-x: 0;
}

.g-2, .gx-2 {
  --bs-gutter-x: 1rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: 1rem;
}

.gy-3 {
  --bs-gutter-y: 1.5rem;
}

.gx-4 {
  --bs-gutter-x: 2rem;
}

.gy-4 {
  --bs-gutter-y: 2rem;
}

.gx-6 {
  --bs-gutter-x: 3rem;
}

.gy-6 {
  --bs-gutter-y: 3rem;
}

@media (width >= 36em) {
  .col-sm-6 {
    flex: none;
    width: 50%;
  }
}

@media (width >= 48em) {
  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }
}

@media (width >= 62em) {
  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }
}

@media (width >= 75em) {
  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }
}

@media (width >= 93.75em) {
  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.opacity-50 {
  opacity: .5;
}

.overflow-hidden {
  overflow: hidden;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm);
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.start-0 {
  left: 0;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-1 {
  margin-top: .5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mt-auto {
  margin-top: auto;
}

.me-3 {
  margin-right: 1.5rem;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.ms-0 {
  margin-left: 0;
}

.ms-auto {
  margin-left: auto;
}

.p-1 {
  padding: .5rem;
}

.p-2 {
  padding: 1rem;
}

.p-3 {
  padding: 1.5rem;
}

.p-4 {
  padding: 2rem;
}

.p-5 {
  padding: 2.5rem;
}

.px-1 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pe-2 {
  padding-right: 1rem;
}

.pe-5 {
  padding-right: 2.5rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.ps-1 {
  padding-left: .5rem;
}

.ps-3 {
  padding-left: 1.5rem;
}

.gap-1 {
  gap: .5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-5 {
  gap: 2.5rem;
}

.gap-6 {
  gap: 3rem;
}

.row-gap-1 {
  row-gap: .5rem;
}

.row-gap-2 {
  row-gap: 1rem;
}

.row-gap-3 {
  row-gap: 1.5rem;
}

.row-gap-5 {
  row-gap: 2.5rem;
}

.column-gap-1 {
  column-gap: .5rem;
}

.column-gap-4 {
  column-gap: 2rem;
}

.column-gap-5 {
  column-gap: 2.5rem;
}

.text-start {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-nowrap {
  white-space: nowrap;
}

@media (width >= 36em) {
  .flex-sm-row {
    flex-direction: row;
  }

  .align-items-sm-center {
    align-items: center;
  }

  .ms-sm-auto {
    margin-left: auto;
  }
}

@media (width >= 48em) {
  .flex-md-row {
    flex-direction: row;
  }

  .justify-content-md-end {
    justify-content: flex-end;
  }

  .align-items-md-center {
    align-items: center;
  }

  .mt-md-3 {
    margin-top: 1.5rem;
  }

  .px-md-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .py-md-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .gap-md-3 {
    gap: 1.5rem;
  }

  .gap-md-4 {
    gap: 2rem;
  }

  .gap-md-5 {
    gap: 2.5rem;
  }

  .row-gap-md-5 {
    row-gap: 2.5rem;
  }
}

@media (width >= 62em) {
  .d-lg-block {
    display: block;
  }

  .d-lg-flex {
    display: flex;
  }

  .d-lg-none {
    display: none;
  }

  .flex-lg-row {
    flex-direction: row;
  }

  .justify-content-lg-between {
    justify-content: space-between;
  }

  .align-items-lg-center {
    align-items: center;
  }

  .px-lg-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .gap-lg-2 {
    gap: 1rem;
  }
}

@media (width >= 75em) {
  .d-xl-flex {
    display: flex;
  }

  .mb-xl-3 {
    margin-bottom: 1.5rem;
  }

  .ms-xl-4 {
    margin-left: 2rem;
  }
}

@media (width >= 93.75em) {
  .p-xxl-5 {
    padding: 2.5rem;
  }

  .px-xxl-5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .pe-xxl-3 {
    padding-right: 1.5rem;
  }

  .gap-xxl-2 {
    gap: 1rem;
  }
}

@media print {
  @page {
    size: auto;
    margin: 10mm 12mm;
  }

  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  body {
    margin: 0;
    padding: 0;
  }

  .pdf-header:after {
    content: "";
    background-color: #dadfe3;
    width: 100%;
    max-width: 100%;
    height: .0625rem;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
  }

  .pdf-footer__url {
    text-decoration-color: #63666a;
  }

  .pdf-footer__right-side {
    gap: 2rem;
  }

  .s-pdf-variant__grid {
    grid-template-columns: 1fr 1fr;
    display: grid;
    gap: 1rem !important;
  }

  .s-pdf-variant__description {
    gap: 2rem;
  }

  .s-pdf-variant__specs:before {
    content: "";
    pointer-events: none;
    background-color: #dadfe3;
    width: 100%;
    height: .0625rem;
    position: absolute;
    top: -.75rem;
    left: 0;
    transform: translateY(-50%);
  }

  .s-pdf-variant__code-number {
    font-weight: 600;
  }

  .pdf-header__body .img {
    max-width: 7.75rem !important;
  }

  .pdf-footer__body .img {
    max-width: 6rem !important;
  }

  .i-pdf-variant-parameter {
    padding-inline: .75rem;
    font-size: 7.5pt !important;
    line-height: 10.5pt !important;
  }

  .i-pdf-variant-parameter:nth-child(odd) {
    background-color: #dadfe3;
  }

  .i-pdf-variant-parameter__title {
    min-width: 7.5rem;
  }

  .s-pdf-variant {
    margin-inline: .5rem !important;
  }

  .s-pdf-variant__photometrics {
    margin-top: 1rem !important;
    margin-bottom: .5rem !important;
  }

  .s-pdf-variant__photometrics a {
    font-size: 7.5pt !important;
  }

  .s-pdf-variant__content {
    gap: 0 !important;
  }

  .s-pdf-variant__meta-label {
    min-width: 4.5rem !important;
  }

  .s-pdf-variant__code {
    transform: translateY(-.5rem) !important;
  }

  .s-pdf-variant__code-label.u-fz-md {
    line-height: 18pt !important;
  }

  .pdf-header-column__title {
    font-weight: 700;
  }

  .u-fz-xl {
    font-size: 9pt !important;
    line-height: 12pt !important;
  }

  .u-fz-lg {
    font-size: 7.5pt !important;
  }

  .u-fz-md {
    font-size: 7.5pt !important;
    line-height: 10.5pt !important;
  }

  .u-fz-xs {
    font-size: 6pt !important;
  }

  .u-h3 {
    font-size: 12pt !important;
  }

  .u-h5 {
    font-size: 7.5pt !important;
    line-height: 9pt !important;
  }

  .i-pdf-spec {
    width: calc(33.3333% - 1.66667rem);
  }

  .i-pdf-spec__content.entry-content {
    font-size: 6pt !important;
    line-height: 7.5pt !important;
  }

  .entry-content {
    font-size: 7.5pt !important;
    line-height: 10.5pt !important;
  }

  .entry-content li {
    margin-bottom: .25rem !important;
    padding-left: 1.5rem !important;
  }

  .entry-content li:before {
    width: .25rem !important;
    height: .25rem !important;
    top: .25rem !important;
  }
}

.container {
  padding-left: 1.625rem;
  padding-right: 1.625rem;
}

@media (width <= 35.999em) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width <= 359px) {
  .container {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.fancybox__container {
  --fancybox-accent-color: #476cfe;
}

.flatpickr-calendar {
  --theme-color: #363738;
  --theme-color-hover: #476cfe;
}

.flatpickr-calendar .flatpickr-day.selected, .flatpickr-calendar .flatpickr-day.selected:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);
}

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--theme-color);
}

.swiper {
  --swiper-pagination-color: #363738;
  overflow: hidden;
}

.swiper-wrapper {
  display: flex;
}

@font-face {
  font-family: Roobert CEZ;
  src: url("RoobertCEZ-Regular.1090b2de.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: Roobert CEZ;
  src: url("RoobertCEZ-Medium.3cb7ddab.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: Roobert CEZ;
  src: url("RoobertCEZ-SemiBold.f25b138a.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: Roobert CEZ;
  src: url("RoobertCEZ-Bold.70ea2b35.woff") format("woff");
  font-weight: 700;
}

body {
  color: #63666a;
  -webkit-font-smoothing: antialiased;
  min-height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
  min-height: 100vh;
  font-family: Roobert CEZ, sans-serif;
  line-height: 1.5;
  display: flex;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale !important;
}

@supports (-webkit-touch-callout: none) {
  body {
    min-height: -webkit-fill-available;
  }
}

figure {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  text-wrap: balance;
  color: #363738;
  margin-bottom: 0;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  height: 100%;
}

iframe {
  max-width: 100%;
  display: block;
}

img {
  opacity: 1;
  max-width: 100%;
  height: auto;
  transition: opacity .5s;
  display: block;
}

img[data-src], img[data-srcset] {
  min-width: .0625rem;
  min-height: .0625rem;
  display: block;
}

img[data-src]:not(.loaded) {
  opacity: 0;
}

a {
  color: #f24f00;
  font-weight: 500;
  text-decoration: underline #0000;
  transition: text-decoration-color .3s, color .3s;
  display: inline;
}

a:hover, a:focus {
  color: #363738;
  text-decoration-color: #363738;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

main {
  flex-grow: 1;
  display: block;
}

p {
  text-wrap: pretty;
}

:root {
  --section-spacing: 5rem;
}

@media (width <= 35.999em) {
  :root {
    --section-spacing: 3rem;
  }
}

strong, b {
  font-weight: 500;
}

@media (width <= 61.999em) {
  .entry-content .column h2:first-of-type, .entry-content .column h3:first-of-type, .entry-content .column h4:first-of-type, .entry-content .column h5:first-of-type, .entry-content .column h6:first-of-type {
    margin-top: 0;
  }
}

@media (width <= 61.999em) {
  .entry-content .columns-2, .entry-content .columns-3 {
    flex-direction: column;
    display: flex;
  }
}

@media (width <= 74.999em) {
  .entry-content .columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 61.999em) {
  .entry-content .columns-4 {
    flex-direction: column;
    display: flex;
  }
}

h1, h2, h3, h4, button, input, label {
  line-height: 1.1;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
}

progress {
  vertical-align: baseline;
}

* {
  min-width: 0;
}

label[for] {
  cursor: pointer;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

.entry-content .alignnone {
  margin: .3125rem 1.25rem 1.25rem 0;
}

.entry-content .alignright {
  float: right;
  margin-left: 1.875rem;
}

.entry-content .alignright img {
  margin-block: 0;
}

.entry-content .alignleft {
  float: left;
  margin-right: 1.875rem;
}

.entry-content .alignleft img {
  margin-block: 0;
}

.entry-content .aligncenter, .entry-content div.aligncenter {
  margin: 3rem auto;
  display: block;
}

.entry-content .aligncenter img, .entry-content div.aligncenter img {
  margin-block: 0;
  margin-inline: auto;
}

@media (width <= 35.999em) {
  .entry-content .alignleft, .entry-content .alignright {
    float: none;
    margin: 0;
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.screen-reader-text {
  clip: rect(.0625rem, .0625rem, .0625rem, .0625rem);
  width: .0625rem;
  height: .0625rem;
  overflow: hidden;
  position: absolute !important;
}

.screen-reader-text:focus {
  color: #21759b;
  z-index: 100000;
  background-color: #f1f1f1;
  border-radius: .1875rem;
  width: auto;
  height: auto;
  padding: .9375rem 1.4375rem .875rem;
  font-size: .875rem;
  font-weight: bold;
  line-height: normal;
  text-decoration: none;
  display: block;
  top: .3125rem;
  left: .3125rem;
  box-shadow: 0 0 .125rem .125rem #0009;
  clip: auto !important;
}

.u-bg-primary {
  background-color: #363738;
}

.u-bg-primary-100 {
  background-color: #eef1f3;
}

.u-bg-primary-200 {
  background-color: #dadfe3;
}

.u-bg-gray {
  background-color: #a9a9b2;
}

.u-bg-white {
  background-color: #fff;
}

.u-clamp {
  --lines: 2;
  -webkit-line-clamp: var(--lines);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.u-clamp-4 {
  --lines: 4;
}

.u-clamp-3 {
  --lines: 3;
}

.u-clamp-1 {
  --lines: 1;
}

.u-color-primary {
  color: #363738;
}

.u-color-primary-300 {
  color: #989ea3;
}

.u-color-primary-500 {
  color: #63666a;
}

.u-color-secondary {
  color: #008443;
}

.u-color-secondary-400 {
  color: #00c752;
}

.u-color-conversion-500 {
  color: #f24f00;
}

.u-color-white {
  color: #fff;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-fz-xs {
  --size: .75rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .25rem);
}

.u-fz-sm {
  --size: .875rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .125rem);
}

.u-fz-md {
  --size: 1rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .5rem);
}

.u-fz-lg {
  --size: 1.125rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .375rem);
}

.u-fz-xl {
  --size: 1.25rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .5rem);
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-600 {
  font-weight: 600;
}

.u-fw-700 {
  font-weight: 700;
}

.u-h1 {
  --size: 3.125rem;
  --line-height: calc(var(--size)  + .25rem);
}

@media (width <= 47.999em) {
  .u-h1 {
    --size: 2.125rem;
  }
}

.u-h2 {
  --size: 2.375rem;
  --line-height: calc(var(--size)  + .125rem);
}

@media (width <= 47.999em) {
  .u-h2 {
    --size: 1.875rem;
  }
}

.u-h3 {
  --size: 1.875rem;
  --line-height: calc(var(--size)  + .125rem);
}

@media (width <= 47.999em) {
  .u-h3 {
    --size: 1.625rem;
  }
}

.u-h4 {
  --size: 1.375rem;
  --line-height: calc(var(--size)  + .125rem);
}

@media (width <= 47.999em) {
  .u-h4 {
    --size: 1.25rem;
  }
}

.u-h5 {
  --size: 1.125rem;
  --line-height: calc(var(--size)  + .375rem);
}

@media (width <= 47.999em) {
  .u-h5 {
    --size: 1rem;
  }
}

.u-h1, .u-h2, .u-h3, .u-h4, .u-h5 {
  font-size: var(--size);
  line-height: var(--line-height);
  font-weight: 500;
}

.u-img-hover {
  backface-visibility: hidden;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.u-img-hover img {
  will-change: transform, opacity;
  transition-property: opacity, transform;
}

.u-img-hover:hover img {
  transform: scale(1.03);
}

.--loading {
  pointer-events: none;
  z-index: 4;
  position: relative;
}

.--loading:before {
  content: "";
  z-index: 9000;
  opacity: .7;
  background-color: #fff;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.--loading:after {
  content: "";
  z-index: 9001;
  border: .25rem solid #fa6900;
  border-bottom-color: #363738;
  border-radius: 50%;
  width: 1.875rem;
  height: 1.875rem;
  margin-top: -.9375rem;
  margin-left: -.9375rem;
  animation: 1s cubic-bezier(.68, -.55, .27, 1.55) infinite rotation;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.u-scrollbar .os-scrollbar {
  --os-handle-bg: #dadfe3;
  --os-handle-bg-hover: #dadfe3;
  --os-handle-bg-active: #989ea3;
  --os-handle-border-radius: 0;
  --os-size: 2rem;
  --os-track-bg: #eef1f3;
  --os-track-bg-hover: #eef1f3;
  --os-track-bg-active: #eef1f3;
  --os-track-border-radius: 0;
  --os-padding-perpendicular: .75rem;
  --os-padding-axis: 1rem;
  cursor: grab;
}

.u-scrollbar .os-scrollbar:active {
  cursor: grabbing;
}

.u-posr {
  position: relative;
}

.u-posa {
  position: absolute;
}

.u-fixed {
  position: fixed;
}

.u-sticky {
  position: sticky;
}

.u-top-50-p {
  top: 50%;
}

.u-left-50-p {
  left: 50%;
}

.u-top-0 {
  top: 0;
}

.u-bottom-0 {
  bottom: 0;
}

.u-left-0 {
  left: 0;
}

.u-right-0 {
  right: 0;
}

.u-translate-middle-x {
  transform: translateX(-50%);
}

.u-translate-middle-y {
  transform: translateY(-50%);
}

.u-translate-middle {
  transform: translateX(-50%)translateY(-50%);
}

.u-rounded-full {
  border-radius: 624.938rem;
}

.u-z-index-1 {
  z-index: 1;
}

.u-z-index-2 {
  z-index: 2;
}

.u-z-index-3 {
  z-index: 3;
}

.u-z-index-negative {
  z-index: -1;
}

.u-z-index-10 {
  z-index: 10;
}

.base-header {
  --spacing: 2.5rem;
  margin-inline: auto;
  margin-bottom: var(--spacing);
  max-width: 58rem;
}

.base-header:has(.base-heading.--decoration-after) {
  max-width: 100%;
}

.base-header.--left {
  text-align: left;
  align-items: flex-start;
  margin-inline: 0;
}

.base-header.--spacing-none {
  --spacing: 0;
}

.base-header.--spacing-sm {
  --spacing: 1.5rem;
}

.base-header.--spacing-lg {
  --spacing: 5rem;
}

@media (width <= 47.999em) {
  .base-header.--spacing-lg {
    --spacing: 2.5rem;
  }
}

.base-header__description p {
  margin-bottom: 0;
}

.base-heading.--decoration-after {
  gap: 1rem;
  width: 100%;
  display: inline-flex;
}

.base-heading.--decoration-after .base-heading__decoration-after {
  max-width: 100%;
  height: .0625rem;
}

.base-section {
  --padding-y: 5rem;
  padding-block: var(--padding-y);
}

@media (width <= 35.999em) {
  .base-section {
    --padding-y: 3rem;
  }
}

.base-section.--bg-color:after {
  content: "";
  z-index: -1;
  background-color: #eef1f3;
  width: 100%;
  max-width: 98rem;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.base-section.--top-0 {
  padding-top: 0;
}

.base-section.--bottom-0 {
  padding-bottom: 0;
}

.base-section.--md {
  --padding-y: 2.5rem;
}

.base-section.--sm {
  --padding-y: 1.5rem;
}

@media (width <= 35.999em) {
  .base-section.--sm {
    --padding-y: 1rem;
  }
}

.base-section.--top-sm-0 {
  --padding-y: 1.5rem;
  padding-top: 0;
}

@media (width <= 35.999em) {
  .base-section.--top-sm-0 {
    --padding-y: 1rem;
  }
}

.base-section.--none {
  --padding-y: 0;
}

.btn {
  --direction-offset: .5rem;
}

.btn.--size-xs {
  --direction-offset: .25rem;
}

@media (hover: hover) {
  .btn.--animation-right:hover svg {
    transform: translateX(var(--direction-offset));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-left:hover svg {
    transform: translateX(calc(-1 * var(--direction-offset)));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-zoom:hover svg {
    transform: scale(1.1);
  }

  .btn.--animation-down:hover svg {
    transform: translateY(var(--direction-offset));
  }
}

.btn.--sm {
  --font-size: .875rem;
  --padding-y: .5rem;
  --padding-x: .75rem;
  --min-height: 2.5rem;
}

.btn.--xs {
  --font-size: .75rem;
  --padding-y: .75rem;
  --padding-x: 1.5rem;
  --min-height: 2.5rem;
  --svg-size: 1rem;
}

.btn.--primary {
  background-color: #fa6900;
  overflow: hidden;
}

.btn.--primary:before {
  content: "";
  pointer-events: none;
  z-index: -1;
  transform-origin: 0 0;
  background-color: #363738;
  width: 200%;
  height: 100%;
  transition: all .6s;
  position: absolute;
  top: 0;
  left: 0;
  transform: skewX(45deg)translateX(100%);
}

@media (hover: hover) {
  .btn.--primary:hover:before {
    transform: skewX(45deg)translateX(-50%);
  }
}

.btn.--outline {
  --font-color: #f24f00;
  --border-color: #f24f00;
  border: .0625rem solid var(--border-color);
  background-color: #fff;
  transition: all .6s;
  overflow: hidden;
}

.btn.--outline:before {
  content: "";
  pointer-events: none;
  z-index: -1;
  transform-origin: 0 0;
  background-color: #fa6900;
  width: 200%;
  height: 100%;
  transition: transform .6s;
  position: absolute;
  top: 0;
  left: 0;
  transform: skewX(45deg)translateX(100%);
}

@media (hover: hover) {
  .btn.--outline:hover {
    --font-color: #fff;
    --border-color: #fa6900;
  }

  .btn.--outline:hover:before {
    transform: skewX(45deg)translateX(-50%);
  }
}

.btn.--ghost {
  --background: transparent;
  --font-color: #f24f00;
  background-color: var(--background);
}

.btn.--facebook {
  --svg-size: 1rem;
  --border-radius: 2.875rem;
  --font-color: #2058c4;
  --border-color: #2058c4;
  --background: #fff;
  --text-transform: capitalize;
  background-color: var(--background);
  border: .0625rem solid var(--border-color);
  will-change: border-color, background-color, color;
  gap: 1rem;
  transition: border-color .3s, background-color .3s, color .3s;
}

.btn.--facebook svg {
  margin-left: -.0625rem;
}

.btn.--facebook path {
  fill: var(--font-color);
  transition: fill .3s;
}

@media (hover: hover) {
  .btn.--facebook:hover {
    --font-color: #fa6900;
    --border-color: #fa6900;
    --background: #fff;
  }
}

.btn.--twitter {
  --svg-size: 1rem;
  --border-radius: 2.875rem;
  --font-color: #081828;
  --border-color: #081828;
  --background: #fff;
  --text-transform: capitalize;
  background-color: var(--background);
  border: .0625rem solid var(--border-color);
  will-change: border-color, background-color, color;
  gap: 1rem;
  transition: border-color .3s, background-color .3s, color .3s;
}

.btn.--twitter path {
  fill: var(--font-color);
  transition: fill .3s;
}

@media (hover: hover) {
  .btn.--twitter:hover {
    --font-color: #fa6900;
    --border-color: #fa6900;
    --background: #fff;
  }
}

.btn.--instagram {
  --svg-size: 1rem;
  --border-radius: 2.875rem;
  --font-color: #d755ca;
  --border-color: #d755ca;
  --background: #fff;
  --text-transform: capitalize;
  background-color: var(--background);
  border: .0625rem solid var(--border-color);
  will-change: border-color, background-color, color;
  gap: 1rem;
  transition: border-color .3s, background-color .3s, color .3s;
}

.btn.--instagram path {
  fill: var(--font-color);
  transition: fill .3s;
}

@media (hover: hover) {
  .btn.--instagram:hover {
    --font-color: #fa6900;
    --border-color: #fa6900;
    --background: #fff;
  }
}

.btn.--linkedin {
  --svg-size: 1rem;
  --border-radius: 2.875rem;
  --font-color: #434dac;
  --border-color: #434dac;
  --background: #fff;
  --text-transform: capitalize;
  background-color: var(--background);
  border: .0625rem solid var(--border-color);
  will-change: border-color, background-color, color;
  gap: 1rem;
  transition: border-color .3s, background-color .3s, color .3s;
}

.btn.--linkedin path {
  fill: var(--font-color);
  transition: fill .3s;
}

@media (hover: hover) {
  .btn.--linkedin:hover {
    --font-color: #fa6900;
    --border-color: #fa6900;
    --background: #fff;
  }
}

.btn.--youtube {
  --svg-size: 1rem;
  --border-radius: 2.875rem;
  --font-color: red;
  --border-color: red;
  --background: #fff;
  --text-transform: capitalize;
  background-color: var(--background);
  border: .0625rem solid var(--border-color);
  will-change: border-color, background-color, color;
  gap: 1rem;
  transition: border-color .3s, background-color .3s, color .3s;
}

.btn.--youtube path {
  fill: var(--font-color);
  transition: fill .3s;
}

@media (hover: hover) {
  .btn.--youtube:hover {
    --font-color: #fa6900;
    --border-color: #fa6900;
    --background: #fff;
  }
}

@font-face {
  font-family: Roobert CEZ;
  src: url("RoobertCEZ-Regular.1090b2de.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: Roobert CEZ;
  src: url("RoobertCEZ-Medium.3cb7ddab.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: Roobert CEZ;
  src: url("RoobertCEZ-SemiBold.f25b138a.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: Roobert CEZ;
  src: url("RoobertCEZ-Bold.70ea2b35.woff") format("woff");
  font-weight: 700;
}

.btn__icon .icon {
  --size: var(--svg-size);
}

.btn__icon svg {
  will-change: transform;
  transition: transform .3s;
}

.btn {
  --border-radius: 0;
  --font-size: 1rem;
  --font-weight: 700;
  --font-color: #fff;
  --font-family: Roobert CEZ, sans-serif;
  --padding-y: 1rem;
  --padding-x: 2rem;
  --min-height: 3.5rem;
  --svg-size: 1.5rem;
  --text-transform: uppercase;
  font-size: var(--font-size);
  font-family: var(--font-family);
  text-transform: var(--text-transform);
  color: var(--font-color);
  font-weight: var(--font-weight);
  border-radius: var(--border-radius);
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  min-height: var(--min-height);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  touch-action: manipulation;
  border: none;
  text-decoration: none;
}

.btn:hover {
  color: var(--font-color);
  text-decoration: none;
}

.btn:focus, .btn:active {
  color: var(--font-color);
  outline: 0;
  text-decoration: none;
}

.btn path {
  stroke: var(--font-color);
  transition: stroke .3s;
}

.btn.--icon-before .btn__icon {
  order: -1;
}

.btn.--icon-only {
  width: var(--min-height);
  height: var(--min-height);
  justify-content: center;
  align-items: center;
  min-height: auto;
  padding: 0;
  display: flex;
}

.btn.--icon-only .btn__title {
  display: none;
}

@media (width <= 35.999em) {
  .btn.--full-width-mobile {
    width: 100%;
  }

  .btn {
    --padding-x: 1rem;
  }
}

@media (width >= 75em) {
  .content-w-aside__content {
    grid-column: 1 / span 8;
  }
}

@media (width <= 74.999em) {
  .content-w-aside__content > .s-breadcrumbs {
    display: none;
  }
}

@media (width >= 75em) {
  .content-w-aside__aside {
    grid-column: span 4;
  }

  .content-w-aside__aside > .s-breadcrumbs {
    display: none;
  }
}

@media (width <= 74.999em) {
  .content-w-aside__aside {
    order: -1;
  }
}

.content-w-aside {
  --padding-y: 5rem;
  padding-block: var(--padding-y);
}

@media (width <= 35.999em) {
  .content-w-aside {
    --padding-y: 3rem;
  }
}

.content-w-aside.--top-0 {
  padding-top: 0;
}

.content-w-aside.--none {
  --padding-y: 0;
}

.content-w-aside.--top-md {
  padding-top: 2.5rem;
}

@media (width <= 74.999em) {
  .content-w-aside.--top-md.content-w-aside-category {
    padding-top: 0;
  }
}

@media (width <= 35.999em) {
  .content-w-aside.--top-md {
    padding-top: 1.5rem;
  }
}

.content-w-aside.--reverse .content-w-aside__aside {
  order: -1;
  grid-column: span 3;
}

.content-w-aside.--reverse .content-w-aside__content {
  grid-column: 4 / span 9;
}

.content-w-aside__row {
  --gap: 2rem;
  gap: var(--gap);
}

@media (width <= 74.999em) {
  .content-w-aside__row {
    --gap: 5rem;
  }
}

@media (width <= 35.999em) {
  .content-w-aside__row {
    --gap: 2.5rem;
  }
}

@media (width >= 75em) {
  .content-w-aside__row {
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;
    display: grid;
  }
}

@media (width <= 74.999em) {
  .content-w-aside__row {
    flex-direction: column;
    display: flex;
  }
}

.f-checkbox__visual {
  width: var(--size);
  height: var(--size);
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  background-color: #fff;
  transition: border .3s, background-color .3s;
}

.f-checkbox__visual svg {
  opacity: 0;
  transition: opacity .3s, transform .3s;
  transform: scale(.2);
}

.f-checkbox__visual:hover {
  --border-color: #f24f00;
}

.f-checkbox__input {
  left: calc(var(--size) / 2);
  top: calc(var(--size) / 2);
  pointer-events: none;
  opacity: 0;
  transform: translateX(-50%);
}

.f-checkbox__input:checked ~ .f-checkbox__visual {
  --border-color: #fa6900;
  background-color: var(--color);
}

.f-checkbox__input:checked ~ .f-checkbox__visual svg {
  opacity: 1;
  transform: scale(1);
}

.f-checkbox__title a {
  color: var(--color);
  font-weight: 600;
}

.f-checkbox {
  --border-color: #363738;
  --color: #fa6900;
  --radius: 0;
  --size: 1.5rem;
  cursor: pointer;
}

.f-checkbox.--disabled {
  --color: #989ea3;
  --border-color: #dadfe3;
  pointer-events: none;
}

.f-checkbox.--disabled .f-checkbox__input:checked ~ .f-checkbox__visual {
  --border-color: #dadfe3;
  background-color: var(--border-color);
}

.f-checkbox.--disabled .f-checkbox__input:not(:checked) ~ .f-checkbox__visual {
  background-color: #fff;
}

.f-checkbox.--disabled .f-checkbox__title {
  color: var(--color);
}

.f-checkbox.--radio {
  --radius: calc(var(--size) / 2);
}

.f-checkbox-select__header {
  border: .0625rem solid var(--border-color);
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--header-background);
  min-height: 2.5rem;
  padding-block: .375rem;
}

.f-checkbox-select__clear {
  display: none;
}

.f-checkbox-select__clear.--active {
  display: inline-flex;
}

.f-checkbox-select__toggler {
  width: 1rem;
  height: 1rem;
}

.f-checkbox-select__toggler svg {
  transition: scale .3s;
}

.f-checkbox-select__title {
  height: 1.5rem;
}

.f-checkbox-select__title:after {
  content: "";
  pointer-events: none;
  background: transparent linear-gradient(270deg, var(--header-background) 0%, #fff0 100%) 0% 0% no-repeat padding-box;
  z-index: 1;
  width: 1rem;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.f-checkbox-select__body {
  border: .0625rem solid var(--border-color);
  transform-origin: top;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  will-change: opacity, transform;
  background-color: #fff;
  width: 100%;
  margin-top: -.0625rem;
  padding-right: 2.25rem;
  transition: opacity .3s, transform .3s;
  top: calc(100% - .0625rem);
  left: 50%;
  transform: translateX(-50%)scaleY(.25);
}

.f-checkbox-select__body .os-scrollbar-vertical {
  padding-bottom: .5rem;
}

.f-checkbox-select__body-inner {
  max-height: 21.125rem;
}

.f-checkbox-select__body-inner .f-checkbox:last-child {
  margin-bottom: 1rem;
}

.f-checkbox-select {
  --header-background: #fff;
  --border-color: #dadfe3;
}

.f-checkbox-select.--inline {
  --opacity: 0;
  --height: 0;
  --pointer-events: none;
}

.f-checkbox-select.--inline .f-checkbox-select__body {
  height: var(--height);
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  transition: height, opacity;
}

.f-checkbox-select.--active .f-checkbox-select__body {
  opacity: 1;
  pointer-events: auto;
  transform: translateX(-50%)scaleY(1);
}

.f-checkbox-select.--active .f-checkbox-select__toggler svg {
  scale: -1;
}

.f-checkbox-select.--active.--inline {
  --opacity: 1;
  --height: auto;
  --pointer-events: all;
}

.f-checkbox-select.--active.--inline .f-checkbox-select__body {
  transition: height .3s, opacity .3s;
}

.f-checkbox-select .f-label {
  margin-bottom: .5rem;
}

.f-date__icon {
  display: var(--icon-display);
  pointer-events: none;
  padding: .5rem 1rem .5rem .5rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateX(0)translateY(-50%);
}

.f-date__input {
  border-radius: var(--radius);
  cursor: var(--cursor);
}

.f-date__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.f-date__input::-webkit-clear-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.f-date__input::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.f-date {
  --border-color: #a9a9b2;
  --radius: .25rem;
  --icon-display: block;
  --cursor: pointer;
}

.f-date:not(.--native) .f-date__input {
  padding-right: 3rem;
}

.f-date.--native {
  --icon-display: none;
  --cursor: auto;
}

.f-date.--invalid {
  --border-color: #e21c3d;
}

.f-date__wrap {
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color .3s;
}

.f-error {
  --display: none;
  color: #e21c3d;
  display: var(--display);
  z-index: 2;
  width: 100%;
  position: absolute;
  top: 100%;
  left: .25rem;
}

.f-error.--show {
  --display: flex;
}

.f-error__icon {
  height: 1.375rem;
}

.f-error__icon path {
  fill: #e21c3d;
}

.f-base {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  width: 100%;
  min-height: 3rem;
  padding: .5rem;
  display: block;
}

.f-base:focus {
  border-color: none;
  outline: none;
}

.f-base::placeholder {
  opacity: 1;
  color: #63666a;
}

textarea.f-base {
  resize: none;
  min-height: 7.9375rem;
}

.i-selected-file {
  cursor: pointer;
  background-color: #eef1f3;
  min-height: 1.5rem;
}

.i-selected-file__remove {
  justify-content: center;
  align-items: center;
  width: .75rem;
  height: .75rem;
  display: flex;
}

.i-selected-file__remove svg {
  width: 100%;
  height: 100%;
}

.f-file.--empty .f-file__files, .f-file__input {
  display: none;
}

.f-label.--required:after {
  content: "*";
  color: #63666a;
  padding-left: .25rem;
}

.f-range-slider {
  --height: .25rem;
  --thumb-size: 1rem;
  --thumb-offset: .5rem;
  --color: #363738;
  --background: #dadfe3;
  --gradient-from: calc(var(--background-from)  - var(--thumb-offset));
  --gradient-to: calc(var(--background-to)  + var(--thumb-offset));
  --opacity: 1;
  --pointer-events: all;
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  gap: 2.25rem;
  transition: opacity .3s;
}

.f-range-slider.--disabled {
  --opacity: .4;
  --pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.f-range-slider__ranges {
  height: var(--height);
}

.f-range-slider__range-wrap:before {
  content: "";
  height: var(--height);
  background-color: var(--background);
  z-index: -1;
  border-radius: var(--height);
  position: absolute;
  inset: 0;
}

.f-range-slider__range {
  -webkit-appearance: none;
  appearance: none;
  pointer-events: none;
  inset: 0 var(--thumb-offset);
  height: var(--height);
  width: calc(100% - var(--thumb-size));
  background: linear-gradient(90deg, transparent 0%, transparent var(--gradient-from), var(--color) var(--gradient-from), var(--color) var(--gradient-to), transparent var(--gradient-to), transparent 100%);
  outline: none;
  position: absolute;
}

.f-range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  pointer-events: var(--pointer-events);
  width: var(--thumb-size);
  height: var(--thumb-size);
  cursor: pointer;
  transform: translateX(calc(var(--thumb-offset) * var(--side)));
  z-index: 2;
  background-color: #fa6900;
  border-radius: 50%;
  transition: background-color .2s;
  position: relative;
}

.f-range-slider__range::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  pointer-events: var(--pointer-events);
  width: var(--thumb-size);
  height: var(--thumb-size);
  cursor: pointer;
  transform: translateX(calc(var(--thumb-offset) * var(--side)));
  z-index: 2;
  background-color: #fa6900;
  border-radius: 50%;
  transition: background-color .2s;
  position: relative;
}

.f-range-slider__range.--from {
  --side: -1;
}

.f-range-slider__range.--to {
  --side: 1;
}

.f-range-slider__label.--from, .f-range-slider__label.--to {
  white-space: nowrap;
  bottom: .875rem;
}

.f-range-slider__inputs {
  display: none;
}

.f-single-select__select.slim-select.ss-main {
  padding-inline: var(--padding-inline);
}

.f-single-select__select.slim-select.ss-main:focus {
  box-shadow: none;
}

.f-single-select__select.slim-select.ss-values.ss-single {
  margin: 0;
}

.f-single-select__select.slim-select .ss-list .ss-option {
  padding-inline: 1rem;
}

.f-single-select, .f-single-select__select.slim-select {
  --radius: .25rem;
  --height: 3rem;
  --padding-inline: 1rem;
  --border-color: #a9a9b2;
  --ss-primary-color: #363738;
  --ss-bg-color: #fff;
  --ss-font-color: #081828;
  --ss-font-placeholder-color: #a9a9b2;
  --ss-disabled-color: #dcdee2;
  --ss-border-color: var(--border-color);
  --ss-highlight-color: #fffb8c;
  --ss-success-color: #00b755;
  --ss-error-color: #dc3545;
  --ss-main-height: var(--height);
  --ss-content-height: 18.75rem;
  --ss-spacing-l: .4375rem;
  --ss-spacing-m: .3125rem;
  --ss-spacing-s: .1875rem;
  --ss-animation-timing: .2s;
  --ss-border-radius: var(--radius);
}

.f-single-select.--native .f-single-select__wrap, .f-single-select__select.slim-select.--native .f-single-select__wrap {
  grid-template-areas: "select";
  align-items: center;
  display: grid;
}

.f-single-select.--native .f-single-select__wrap:after, .f-single-select__select.slim-select.--native .f-single-select__wrap:after {
  content: "";
  pointer-events: none;
  background-image: url("chevron-down.68e316cd.svg");
  background-repeat: no-repeat;
  background-size: contain;
  grid-area: select;
  justify-self: end;
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  display: block;
}

.f-single-select.--native .f-single-select__select, .f-single-select__select.slim-select.--native .f-single-select__select {
  border-radius: var(--radius);
  min-height: var(--height);
  border: .0625rem solid var(--border-color);
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  outline: none;
  grid-area: select;
  width: 100%;
  margin: 0;
  padding-left: 1rem;
}

.f-single-select.--invalid, .f-single-select__select.slim-select.--invalid, .f-single-select.--invalid .ss-main, .f-single-select__select.slim-select.--invalid .ss-main {
  --border-color: #e21c3d;
}

.f-switch {
  --width: 3rem;
  --height: 1.5rem;
  --offset: .25rem;
  --background: #989ea3;
  --background-checked: #363738;
  --transition-timing: .2s;
  --border-radius: calc(var(--height) / 2);
  --handle-size: calc(var(--height)  - var(--offset) * 2);
  --handle-radius: calc(var(--handle-size) / 2);
  --handle-background: #fff;
  --handle-translate-x: var(--offset);
  --handle-translate-x-checked: calc(var(--width)  - var(--handle-size)  - var(--offset));
}

.f-switch.--disabled {
  --background: #a9a9b2;
  --background-checked: #a9a9b2;
  --handle-background: #ebebeb;
}

.f-switch__visual {
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background-color: var(--background);
  transition: background-color var(--transition-timing) ease;
  will-change: background-color;
}

.f-switch__visual:after {
  content: "";
  transform: translateY(-50%) translateX(var(--handle-translate-x));
  width: var(--handle-size);
  height: var(--handle-size);
  border-radius: var(--handle-radius);
  background-color: var(--handle-background);
  z-index: 2;
  transition: transform var(--transition-timing) ease;
  will-change: transform;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.f-switch__input {
  left: 0;
  top: calc(var(--height) / 2);
  pointer-events: none;
  opacity: 0;
}

.f-switch__input:checked ~ .f-switch__visual {
  --background: var(--background-checked);
  --handle-translate-x: var(--handle-translate-x-checked);
}

.f-text__icon {
  pointer-events: none;
  position: absolute;
}

.f-text__icon.--before {
  padding-left: .5rem;
  top: 50%;
  left: 0;
  transform: translateX(0)translateY(-50%);
}

.f-text__icon.--after {
  padding-right: .5rem;
  top: 50%;
  right: 0;
  transform: translateX(0)translateY(-50%);
}

.f-text__input {
  border-radius: var(--radius);
  color: var(--font-color);
}

.f-text {
  --font-color: #363738;
  --border-color: #989ea3;
  --radius: 0;
}

.f-text.--invalid {
  --border-color: #e21c3d;
}

.f-text.--icon-before .f-text__input {
  padding-left: 2.5rem;
}

.f-text.--icon-after .f-text__input {
  padding-right: 2.5rem;
}

.f-text:has(.f-text__input:focus) {
  --border-color: #63666a;
}

.f-text__wrap {
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color .3s;
}

.f-text__toggle {
  --on-opacity: 0;
  --off-opacity: 1;
  --size: 1.5rem;
  width: var(--size);
  height: var(--size);
  right: 1rem;
}

.f-text__toggle.--active {
  --on-opacity: 1;
  --off-opacity: 0;
}

.f-text__toggle-icon {
  width: var(--size);
  height: var(--size);
  grid-row-start: 1;
  grid-column-start: 1;
}

.f-text__toggle-icon.--on {
  opacity: var(--on-opacity);
}

.f-text__toggle-icon.--off {
  opacity: var(--off-opacity);
}

.f-textarea__input {
  color: var(--font-color);
  border-radius: var(--radius);
}

.f-textarea {
  --font-color: #363738;
  --border-color: #989ea3;
  --radius: 0;
}

.f-textarea.--invalid {
  --border-color: #e21c3d;
}

.f-textarea:has(.f-textarea__input:focus) {
  --border-color: #63666a;
}

.f-textarea__wrap {
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color .3s;
}

@media (width >= 93.75em) {
  .f-category.--cols-5 .col-xxl-2 {
    width: 20%;
  }
}

.f-contact {
  --padding: 1.5rem;
  --max-width: 100%;
  max-width: var(--max-width);
  padding: var(--padding);
  background: #eef1f3;
}

@media (width >= 48em) {
  .f-contact {
    --padding: 2.5rem;
  }
}

@media (width <= 35.999em) {
  .f-contact {
    --max-width: 30.5rem;
    margin-inline: auto;
  }
}

@media (width >= 48em) {
  .f-contact__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.f-contact__terms a {
  font-weight: 400;
}

@media (width >= 36em) {
  .f-contact__submit {
    margin-left: auto;
  }
}

@media (width <= 35.999em) {
  .f-contact__submit {
    width: 100%;
  }
}

.f-filtration__header {
  min-height: var(--min-height);
  background-color: var(--bg-header);
  border-bottom: .125rem solid #dadfe3;
  transition: background-color .3s;
}

.f-filtration__body {
  display: var(--display);
}

.f-filtration__body-inner {
  border: .0625rem solid #dadfe3;
  border-top: 0;
}

.f-filtration__icon path {
  fill: #363738;
}

.f-filtration {
  --min-height: 4.5rem;
  --bg-header: #fff;
  --display: none;
}

.f-filtration.--open {
  --bg-header: #eef1f3;
  --display: grid;
}

.f-filtration.--default .f-filtration__form-list .f-checkbox-select__body {
  position: absolute;
}

@media (width <= 35.999em) {
  .f-filtration.--default .f-filtration__title {
    margin-right: auto;
  }
}

.f-filtration.--inline {
  --min-height: 3.5rem;
  --bg-header: #eef1f3;
  --display: flex;
}

@media (width <= 35.999em) {
  .f-filtration.--inline .f-filtration__header {
    flex-direction: row;
  }
}

.f-filtration.--inline .f-filtration__form-list {
  flex-direction: column;
}

@media (width <= 74.999em) {
  .f-filtration.--inline .f-filtration__form-list {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
  }

  .f-filtration.--inline .f-filtration__form-list .f-checkbox-select__body {
    position: absolute;
  }
}

@media (width <= 61.999em) {
  .f-filtration.--inline .f-filtration__form-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 35.999em) {
  .f-filtration.--inline .f-filtration__form-list {
    display: flex;
  }

  .f-filtration.--inline .f-filtration__form-list .f-checkbox-select__body {
    position: relative;
  }
}

.f-newsletter {
  background-color: #eef1f3;
  border-radius: 1rem;
}

@media (width >= 62em) {
  .f-newsletter__grid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.f-newsletter__header {
  gap: .25rem;
}

@media (width <= 35.999em) {
  .f-newsletter__inputs-col {
    flex-direction: column;
  }
}

@media (width <= 47.999em) {
  .f-newsletter__submit {
    width: 100%;
  }
}

.g-base {
  flex-wrap: wrap;
  display: flex;
}

.g-base.--gap-xs {
  gap: .25rem;
}

.g-base.--gap-sm {
  gap: .5rem;
}

.g-base.--gap-md {
  gap: 1rem;
}

.g-base.--gap-lg {
  gap: 1.5rem;
}

.g-base.--gap-xl {
  gap: 2rem;
}

.g-base.--align-left {
  justify-content: flex-start;
}

.g-base.--align-right {
  justify-content: flex-end;
}

.g-base.--align-center {
  justify-content: center;
}

.g-base.--align-between {
  justify-content: space-between;
}

.g-buttons {
  --spacing: 3rem;
  margin-top: var(--spacing);
}

.g-buttons.--spacing-none {
  --spacing: 0;
}

.g-buttons.--spacing-lg {
  --spacing: 4rem;
}

@media (width <= 47.999em) {
  .g-buttons.--spacing-lg {
    --spacing: 2rem;
  }
}

.g-buttons.--spacing-xl {
  --spacing: 5rem;
}

@media (width <= 47.999em) {
  .g-buttons.--spacing-xl {
    --spacing: 2.5rem;
  }
}

@media (width >= 93.75em) {
  .g-categories.--cols-5 .col-xxl-2 {
    width: 20%;
  }
}

@media (width <= 47.999em) {
  .g-contact-card__cell {
    max-width: 30rem;
    margin-inline: auto;
  }
}

@media (width >= 75em) {
  .g-events {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.g-gallery-slider__wrapper .swiper-slide {
  --border-color: #dadfe3;
  opacity: 1;
}

.g-gallery-slider__wrapper .swiper-slide:before {
  content: "";
  border: .0625rem solid var(--border-color);
  z-index: 2;
  pointer-events: none;
  width: 100%;
  height: 100%;
  transition: border .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.g-gallery-slider__wrapper .swiper-slide:hover {
  --border-color: #363738;
}

.g-gallery-slider__wrapper .swiper-slide.--active {
  --border-color: #fa6900;
}

div.fancybox__nav button.carousel__button {
  --font-color: #f24f00;
  --border-color: #f24f00;
  border: .0625rem solid var(--border-color);
  background-color: #fff;
  border-radius: 0;
  transition: all .6s;
  overflow: hidden;
}

div.fancybox__nav button.carousel__button:after {
  content: "";
  pointer-events: none;
  z-index: -1;
  transform-origin: 0 0;
  background-color: #fa6900;
  width: 200%;
  height: 100%;
  transition: transform .6s;
  position: absolute;
  top: 0;
  left: 0;
  transform: skewX(45deg)translateX(100%);
}

@media (hover: hover) {
  div.fancybox__nav button.carousel__button:hover {
    --font-color: #fff;
    --border-color: #fa6900;
  }

  div.fancybox__nav button.carousel__button:hover:after {
    transform: skewX(45deg)translateX(-50%);
  }
}

div.fancybox__nav button.carousel__button svg {
  display: none;
}

div.fancybox__nav button.carousel__button:before {
  content: "";
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  background-image: url("chevron-right-fancybox.e0f19f83.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  transition: background-image .3s, transform .3s;
  position: absolute;
}

@media (hover: hover) {
  div.fancybox__nav button.carousel__button.is-next:hover:before {
    background-image: url("chevron-right-fancybox-hover.9096bfc7.svg");
    transform: translateX(.3125rem);
  }
}

div.fancybox__nav button.carousel__button.is-prev:before {
  transform: rotate(-180deg);
}

@media (hover: hover) {
  div.fancybox__nav button.carousel__button.is-prev:hover:before {
    background-image: url("chevron-right-fancybox-hover.9096bfc7.svg");
    transform: translateX(-.3125rem)rotate(-180deg);
  }
}

div.fancybox__backdrop {
  background-color: #002c5b66;
}

div.fancybox__thumb {
  border-radius: 0;
}

div.fancybox__thumb:after {
  border-width: .1875rem !important;
  border-color: #fa6900 !important;
  border-radius: 0 !important;
}

.g-hero-slider__button {
  --offset: 50%;
  pointer-events: auto;
}

.g-hero-slider__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-hero-slider__button.--next {
  transform: translateX(var(--offset));
}

@media (width <= 35.999em) {
  .g-hero-slider__button {
    --offset: .5rem;
  }
}

.g-hero-slider__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  min-height: .5rem;
  margin-top: .5rem;
  display: flex;
  position: static;
}

.g-hero-slider__pagination.swiper-pagination .swiper-pagination-bullet {
  transform-origin: center;
  transition: width .3s;
}

.g-hero-slider__pagination.swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 2rem;
}

.g-hero-slider .swiper-slide {
  backface-visibility: hidden;
  flex-shrink: 0;
  transform: translate3d(0, 0, 0);
  width: 100% !important;
}

.g-hero-slider__buttons {
  pointer-events: none;
}

.g-jobs {
  gap: 5rem;
  width: 100%;
  max-width: 58rem;
  margin: 0 auto;
}

.g-jobs.--card {
  max-width: unset;
  flex-flow: wrap;
  justify-content: center;
  gap: 2rem;
  display: flex;
}

@media (width >= 62em) {
  .g-posts-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.g-posts-grid__cell:first-of-type {
  grid-column: 1 / -1;
}

@media (width >= 48em) {
  .g-posts {
    gap: 4.5rem;
  }
}

.g-pricetable {
  overflow-x: auto;
}

.g-pricetable__row:nth-child(odd) {
  background-color: #eef1f3;
}

.g-pricetable__cell .icon {
  margin: 0 auto;
}

.g-product.--hidden {
  display: none;
}

@media (width >= 48em) {
  .g-product {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 93.75em) {
  .g-product {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width <= 47.999em) {
  .g-product {
    max-width: 26.75rem;
    margin-inline: auto;
  }
}

.g-products-slider__button {
  --offset: calc(50% - .25rem);
  pointer-events: auto;
}

@media (width <= 359px) {
  .g-products-slider__button.btn.--outline {
    --min-height: 2rem;
    --offset: calc(50% - .75rem);
  }

  .g-products-slider__button.btn.--outline .icon {
    --size: 1rem;
  }
}

.g-products-slider__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-products-slider__button.--next {
  transform: translateX(var(--offset));
}

.g-products-slider.swiper {
  --wrapper-gap: 2rem;
  --slide-max-width: 20.5rem;
  overflow: initial;
  align-items: center;
  display: flex;
}

.g-products-slider.swiper.swiper-initialized {
  --wrapper-gap: 0;
  --slide-max-width: auto;
}

.g-products-slider .swiper-wrapper {
  align-items: center;
  gap: var(--wrapper-gap);
}

.g-products-slider .swiper-slide {
  backface-visibility: hidden;
  opacity: 0;
  pointer-events: none;
  max-width: var(--slide-max-width);
  flex-shrink: 0;
  transition: opacity .3s;
  transform: translate3d(0, 0, 0);
}

.g-products-slider .swiper-slide-visible {
  opacity: 1;
  pointer-events: auto;
}

.g-products-slider__buttons {
  pointer-events: none;
}

.g-products-slider__buttons .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

@media (width <= 47.999em) {
  .g-signpost {
    max-width: 28rem;
    margin-inline: auto;
  }
}

.g-steps {
  row-gap: 4.5rem;
}

.g-steps.--vertical {
  max-width: 42.875rem;
  margin-inline: auto;
}

.g-testimonials__button {
  --offset: 50%;
  pointer-events: auto;
}

.g-testimonials__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-testimonials__button.--next {
  transform: translateX(var(--offset));
}

@media (width <= 35.999em) {
  .g-testimonials__button {
    --offset: .5rem;
  }
}

.g-testimonials__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  min-height: .5rem;
  margin-top: .5rem;
  display: flex;
  position: static;
}

.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet {
  transform-origin: center;
  transition: width .3s;
}

.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 2rem;
}

.g-testimonials .swiper-slide {
  backface-visibility: hidden;
  flex-shrink: 0;
  width: fit-content;
  transform: translate3d(0, 0, 0);
}

.g-testimonials .swiper-wrapper {
  align-items: center;
}

.g-testimonials__buttons {
  pointer-events: none;
}

.g-testimonials {
  gap: 5rem;
}

@media (width <= 93.749em) {
  .g-testimonials {
    gap: 3.25rem;
  }
}

@media (width <= 61.999em) {
  .g-testimonials {
    gap: 2rem;
  }
}

.g-usps {
  gap: 2rem;
  display: grid;
}

@media (width >= 36em) {
  .g-usps {
    grid-template-columns: repeat(2, 2fr);
  }
}

@media (width >= 62em) {
  .g-usps {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (width >= 75em) {
  .g-usps {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (width >= 36em) {
  .g-variant-parameter {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 62em) {
  .g-variant-parameter {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width >= 75em) {
  .g-variant-parameter {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (width >= 93.75em) {
  .g-variant-parameter {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (width >= 48em) and (width <= 61.999em) {
  .g-widgets {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    display: grid;
  }
}

@media (width <= 47.999em) {
  .g-widgets {
    max-width: 20.5rem;
    margin-inline: auto;
  }
}

.icon {
  --size: 1.5rem;
  color: #081828;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}

.icon svg {
  width: 100%;
  height: 100%;
}

.icon.--colored {
  color: var(--color);
}

.icon.--colored path {
  stroke: var(--color);
  transition: stroke .3s;
}

.icon.--stroke-off path {
  stroke: revert;
}

.icon.--primary {
  --color: #f24f00;
  --color-hover: #363738;
}

.icon.--white {
  --color: #fff;
}

.icon.--black {
  --color: #081828;
}

.icon.--danger {
  --color: #e21c3d;
}

.icon.--4xl {
  --size: 6rem;
}

.icon.--xxxl {
  --size: 3rem;
}

.icon.--xxl {
  --size: 2.5rem;
}

.icon.--xl {
  --size: 2rem;
}

.icon.--lg {
  --size: 1.5rem;
}

.icon.--md {
  --size: 1.25rem;
}

.icon.--sm {
  --size: 1rem;
}

.icon.--xs {
  --size: .875rem;
}

.img {
  overflow: hidden;
}

.img.--ratio-4-3 {
  aspect-ratio: 4 / 3;
}

.img.--ratio-16-9 {
  aspect-ratio: 16 / 9;
}

.img.--ratio-3-2 {
  aspect-ratio: 3 / 2;
}

.img.--ratio-1-1 {
  aspect-ratio: 1;
}

.img.--ratio-3-4 {
  aspect-ratio: 3 / 4;
}

.img.--contain {
  width: 100%;
  height: 100%;
}

.img.--contain img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img.--contain picture, .img.--cover {
  width: 100%;
  height: 100%;
}

.img.--cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img.--cover picture {
  width: 100%;
  height: 100%;
}

.img.--placeholder {
  position: relative;
}

.img.--placeholder:before {
  content: "";
  z-index: -1;
  opacity: .5;
  background-color: #eef1f3;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.i-alert {
  --background: #fff;
  --border-color: #ebebeb;
  --color: #081828;
  --opacity: 0;
  --translate: -2rem;
  --icon-background: #fff;
  background-color: var(--background);
  color: var(--color);
  opacity: var(--opacity);
  border-color: var(--border-color);
  transform: translateY(var(--translate));
  pointer-events: none;
  will-change: opacity, transform;
  border-style: solid;
  border-width: .0625rem;
  border-radius: .25rem;
  transition: opacity .3s, transform .3s;
}

.i-alert.--show {
  --opacity: 1;
  --translate: 0;
  pointer-events: auto;
}

.i-alert.--success {
  --border-color: #48a463;
  --background: #e7f4e4;
  --color: #48a463;
  --icon-background: #48a463;
}

.i-alert.--danger {
  --border-color: #e21c3d;
  --background: #f9dede;
  --color: #e21c3d;
  --icon-background: #e21c3d;
}

.i-alert.--warning {
  --border-color: #fdb81e;
  --background: #fff1d2;
  --color: #fdb81e;
  --icon-background: #fdb81e;
}

.i-alert.--primary {
  --border-color: #363738;
  --background: #363738;
  --color: #fff;
  --icon-background: #363738;
}

.i-alert__icon, .i-alert__close {
  background-color: var(--icon-background);
}

.i-author__description p {
  margin-bottom: 0;
}

.i-author.--compact {
  align-items: center;
}

.i-author.--compact .i-author__description {
  display: none;
}

.i-author__image {
  --size: 2.5rem;
  width: var(--size);
  height: var(--size);
  max-width: var(--size);
}

.i-author__image .img {
  border-radius: 50%;
}

.i-banner__header {
  --max-width: 100%;
  max-width: var(--max-width);
}

@media (width >= 75em) {
  .i-banner__header {
    --max-width: 45.75rem;
  }
}

@media (width >= 93.75em) {
  .i-banner__header {
    --max-width: 60.9375rem;
  }
}

@media (width <= 35.999em) {
  .i-banner__buttons {
    width: 100%;
  }
}

.i-breadcrumb:before {
  content: "";
  background-color: #dadfe3b3;
  width: .0625rem;
  height: .9375rem;
  position: absolute;
  top: 50%;
  left: -1rem;
  transform: translate(0, -50%);
}

.i-breadcrumb.--homepage:before, .i-breadcrumb.--homepage .i-breadcrumb__divider {
  display: none;
}

.i-category__image {
  width: var(--min-height);
}

.i-category {
  --background: #fff;
  --color: #363738;
  --border-color: #dadfe3;
  --min-height: 3.5rem;
  --opacity: 1;
  --pointer-events: all;
  background-color: var(--background);
  min-height: var(--min-height);
  z-index: 1;
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  text-decoration: none;
  transition: background-color .3s, color .3s, border-color .3s, opacity .3s;
}

.i-category:before {
  content: "";
  border: .0625rem solid var(--border-color);
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

@media (hover: hover) {
  .i-category:hover {
    --color: #f24f00;
  }
}

.i-category.--active {
  --background: #363738;
  --color: #fff;
  --border-color: #363738;
}

.i-category.--disabled {
  --opacity: .4;
  --pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.i-category.--input.--radio {
  --min-height: 3.5rem;
}

.i-category.--input.--radio .i-category__image {
  width: var(--min-height);
}

.i-category__title {
  color: var(--color);
  transition: color .3s;
}

.i-category__input {
  pointer-events: none;
  opacity: 0;
}

.i-category:has(.i-category__input:checked) {
  --background: #363738;
  --color: #fff;
  --border-color: #363738;
}

.i-contact-card__body {
  border: .0625rem solid #eef1f3;
  border-top: none;
}

.i-contact-card__map-link {
  --scale: 1;
  --transition: transform .3s ease-in-out;
  transform: scale(var(--scale));
  transform-origin: 100% 100%;
  transition: var(--transition);
  outline: none;
  width: 3rem;
  text-decoration: none;
  bottom: 1rem;
  right: 1rem;
}

.i-contact-card__map-link svg {
  transition: var(--transition);
}

.i-contact-card__map-link:before {
  content: "";
  pointer-events: none;
  z-index: 1;
  border: .0625rem solid #363738;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (hover: hover) {
  .i-contact-card__map-link:hover {
    --scale: 1.67;
  }

  .i-contact-card__map-link:hover svg {
    transform: scale(.67);
  }
}

.i-document {
  --border-color: #dadfe3;
  --color: #363738;
  box-shadow: 0 0 0 .0625rem var(--border-color);
  background-color: #fff;
  height: 100%;
  min-height: 5.5rem;
  transition: box-shadow .3s;
}

@media (hover: hover) {
  .i-document:hover {
    --border-color: #fa6900;
    --color: #f24f00;
  }
}

.i-document__title {
  color: var(--color);
  transition: color .3s;
}

.i-document__metadata {
  padding-block: .25rem;
}

@media (width <= 47.999em) {
  .i-event {
    flex-direction: column;
    max-width: 20rem;
    margin-inline: auto;
  }
}

@media (width >= 48em) {
  .i-event__image-link {
    width: 17.5rem;
    height: 13.125rem;
  }
}

.i-faq__body {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  background-color: #eef1f3;
  grid-template-rows: 0fr;
  transition: grid-template-rows .5s;
  display: grid;
}

.i-faq__body-inner {
  position: relative;
  overflow: hidden;
}

.i-faq__body-inner:before {
  content: "";
  background-color: #989ea3;
  width: 100%;
  height: .0625rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.i-faq__header {
  cursor: pointer;
}

.i-faq {
  --border-radius: .375rem;
  border-radius: var(--border-radius);
  border: .0625rem solid #989ea3;
}

.i-faq svg {
  will-change: transform;
  transition: transform .3s;
}

.i-faq.--open .i-faq__toggler svg {
  transform: scale(-1);
}

.i-faq.--open .i-faq__body {
  grid-template-rows: 1fr;
}

@media (width <= 47.999em) {
  .i-filtration-visual {
    flex-direction: column;
  }
}

.i-filtration-visual:not(:has(.i-selected-tag)) {
  display: none;
}

.i-filtration-visual__reset-btn.btn {
  margin: 0 auto;
  padding-block: .375rem;
}

.i-gallery-slide__image {
  display: block;
}

.i-gallery-slide__image button {
  aspect-ratio: 1;
  background-color: none;
  border: none;
  outline: none;
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
}

.i-gallery-slide__image a {
  outline: none;
  text-decoration: none;
  display: block;
}

.i-gallery-slide__tag.i-tag {
  pointer-events: none;
  top: .5rem;
  right: .5rem;
}

.i-hero-slide__body {
  --radius: .5rem;
  --padding-y: 2.5rem;
  --padding-x: 5rem;
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  width: 100%;
  max-width: 43rem;
  height: auto;
  min-height: 26.75rem;
}

@media (width <= 93.749em) {
  .i-hero-slide__body {
    max-width: 37.5rem;
  }
}

@media (width <= 74.999em) {
  .i-hero-slide__body {
    padding: 1.5rem;
  }
}

@media (width <= 61.999em) {
  .i-hero-slide__body {
    gap: .5rem;
    max-width: 100%;
    height: 100%;
    position: static;
    left: auto;
    right: auto;
    transform: translate3d(0, 0, 0);
  }
}

@media (width <= 35.999em) {
  .i-hero-slide__body {
    padding: 1rem;
  }
}

.i-hero-slide {
  --offset: 0;
  height: 33.75rem;
}

.i-hero-slide.--left .i-hero-slide__body {
  top: 50%;
  left: var(--offset);
  transform: translateY(-50%);
}

@media (width <= 74.999em) {
  .i-hero-slide.--left .i-hero-slide__body {
    --offset: 1.5rem;
  }
}

@media (width <= 61.999em) {
  .i-hero-slide.--left .i-hero-slide__body {
    text-align: center;
    transform: translateY(0);
  }
}

.i-hero-slide.--right .i-hero-slide__body {
  top: 50%;
  right: var(--offset);
  margin-left: auto;
  transform: translateY(-50%);
}

@media (width <= 74.999em) {
  .i-hero-slide.--right .i-hero-slide__body {
    right: 1.5rem;
  }
}

@media (width <= 61.999em) {
  .i-hero-slide.--right .i-hero-slide__body {
    text-align: center;
    transform: translateY(0);
  }
}

.i-hero-slide.--center .i-hero-slide__body {
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (width <= 61.999em) {
  .i-hero-slide.--center .i-hero-slide__body {
    transform: translate3d(0, 0, 0);
  }

  .i-hero-slide {
    padding: 1rem;
  }
}

.i-hero-slide__footer {
  margin-top: 4rem;
}

.i-hero-slide__heading {
  color: #00c752;
  margin-bottom: 1.5rem;
}

@media (width <= 93.749em) {
  .i-hero-slide__heading {
    font-size: 2.75rem;
    line-height: 3rem;
  }
}

@media (width <= 74.999em) {
  .i-hero-slide__heading {
    font-size: 2.5rem;
    line-height: 2.625rem;
  }
}

@media (width <= 61.999em) {
  .i-hero-slide__heading {
    margin-bottom: .5rem;
  }
}

.i-hero-slide__description {
  font-weight: 500;
}

@media (width <= 93.749em) {
  .i-hero-slide__description {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}

@media (width <= 74.999em) {
  .i-hero-slide__description {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

@media (width <= 61.999em) {
  .i-hero-slide__description {
    font-size: 1rem;
  }
}

.i-hero-slide__image:after {
  content: "";
  opacity: .7;
  mix-blend-mode: multiply;
  background: linear-gradient(90deg, #63666a 0% 25%, #63666900 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.i-job__image {
  width: var(--image-size);
  height: var(--image-size);
  border-radius: 1rem;
  overflow: hidden;
}

.i-job__info {
  color: #363738;
  row-gap: .5rem;
}

@media (width >= 48em) {
  .i-job__info {
    flex-direction: row;
  }
}

.i-job {
  --image-size: 9rem;
  flex-direction: column;
}

@media (width >= 48em) {
  .i-job {
    flex-direction: row;
  }
}

.i-job.--card {
  --image-size: 28rem;
  flex-direction: column;
  width: 26rem;
}

.i-job.--card .i-job__image {
  max-width: 100%;
  height: auto;
}

.i-job.--card .i-job__info {
  flex-direction: column;
  gap: 1rem;
}

.i-job.--card .i-job__footer {
  flex-direction: column;
  align-items: flex-start;
}

.i-media.--video {
  cursor: pointer;
}

.i-media.--product .i-media__tag.i-tag {
  pointer-events: none;
  top: .5rem;
  right: .5rem;
}

.i-menu {
  --padding-left: 1rem;
  --padding-right: 1rem;
  --border-color: transparent;
}

.i-menu.--level-1.--current {
  --border-color: #f24f00;
}

@media (width >= 62em) and (width <= 93.749em) {
  .i-menu {
    --padding-left: .75rem;
    --padding-right: .75rem;
  }

  .i-menu:last-child .i-menu__link:after {
    display: none;
  }
}

.i-menu__link {
  color: #363738;
  border-bottom: .25rem solid var(--border-color);
  padding-block: .75rem;
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  z-index: 1;
  min-height: 3.5rem;
  text-decoration: none;
  transition: border .3s;
}

@media (width >= 62em) {
  .i-menu__link:after {
    content: "";
    background-color: #dadfe3;
    width: .0625rem;
    height: 1.5rem;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
  }

  .i-menu__link:hover {
    --border-color: #f24f00;
  }
}

.i-notification {
  color: #fff;
  background-color: #363738;
  min-height: 2.5rem;
}

@media (width <= 35.999em) {
  .i-notification {
    text-align: center;
  }
}

.i-notification__container {
  width: min(100% - 2rem, 52rem);
}

@media (width <= 35.999em) {
  .i-notification__container {
    flex-direction: column;
    gap: .5rem;
  }

  .i-notification__icon {
    margin-inline: auto;
  }

  .i-notification__close {
    order: -1;
  }
}

.i-person__email {
  word-break: break-all;
}

@media (width >= 62em) {
  .i-post.--hero {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
    display: grid;
  }
}

@media (width <= 61.999em) {
  .i-post.--hero {
    max-width: 43rem;
    margin-inline: auto;
  }
}

.i-post.--hero .i-post__tags {
  margin-top: 0;
}

@media (width >= 62em) {
  .i-post.--hero .i-post__content-col {
    padding-block: 2rem;
  }
}

.i-post.--hero .i-post__content {
  justify-content: center;
}

@media (width >= 62em) {
  .i-post.--inline {
    grid-template-columns: minmax(10rem, 20.5rem) auto;
    gap: 2rem;
    display: grid;
  }

  .i-post.--inline .i-post__content {
    max-width: 70ch;
  }
}

.i-post.--default {
  height: 100%;
}

@media (width <= 61.999em) {
  .i-post {
    max-width: 28rem;
    margin-inline: auto;
  }
}

.i-post__heading-link {
  color: #363738;
  text-decoration: none;
}

@media (hover: hover) {
  .i-post__heading-link:hover {
    color: #f24f00;
  }
}

.i-post__arrow-link {
  text-transform: uppercase;
}

.i-post__image-col, .i-post__content-col {
  height: fit-content;
}

.i-priceplan {
  border: .0625rem solid #eef1f3;
  border-radius: 1.5rem;
}

.i-priceplan.--highlight {
  border: none;
  box-shadow: 0 .5rem 1rem .125rem #0f1c7b26;
}

@media (width <= 61.999em) {
  .i-priceplan {
    max-width: 28rem;
    margin-inline: auto;
  }
}

.i-priceplan__ribbon {
  color: #476cfe;
  background: #eef1f3;
  border-radius: .5rem;
  width: calc(100% - 4rem);
  top: -1rem;
}

.i-priceplan__price-before {
  text-decoration: line-through;
}

.i-priceplan__price {
  font-size: 4rem;
}

.i-product {
  border: .0625rem solid #dadfe3;
}

.i-product__content:before {
  content: "";
  pointer-events: none;
  background-color: #eef1f3;
  width: 100%;
  height: .0625rem;
  position: absolute;
  top: -1rem;
  left: 0;
  transform: translateY(-50%);
}

.i-product__image .img {
  --border-color: transparent;
  border: .0625rem solid var(--border-color);
  transition: border-color .3s;
}

.i-product__image .img:before {
  z-index: 1;
}

.i-product__image .img img {
  z-index: 2;
  position: relative;
}

@media (hover: hover) {
  .i-product__image .img:hover {
    --border-color: #fa6900;
  }
}

.i-product__tag.i-tag {
  pointer-events: none;
  top: .5rem;
  right: .5rem;
}

.i-product__specs {
  gap: .375rem;
}

.i-product__image-placeholder {
  aspect-ratio: 1;
  opacity: .5;
}

.i-product-variant__header {
  min-height: var(--min-height);
  grid-template-columns: repeat(10, minmax(7.875rem, 1fr)) 2.5rem;
}

.i-product-variant__body {
  grid-template-rows: 0fr;
  padding: 0;
  transition: grid-template-rows .5s;
}

.--js-open-variant svg {
  transition: transform .3s;
}

.i-product-variant__content {
  --padding: 2.5rem;
  padding: var(--padding);
}

@media (width <= 47.999em) {
  .i-product-variant__content {
    --padding: 1.5rem;
  }
}

.i-product-variant {
  --border-color: transparent;
  --background-color: #fff;
  --min-height: 4rem;
  background-color: var(--background-color);
  width: fit-content;
  min-width: 100%;
  padding-bottom: 0;
  transition: padding-bottom .3s, background-color .3s;
}

.i-product-variant:before {
  content: "";
  border: .0625rem solid var(--border-color);
  pointer-events: none;
  width: 100%;
  height: 100%;
  transition: border-color .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.i-product-variant:after {
  content: "";
  pointer-events: none;
  border-bottom: .125rem solid #dadfe3;
  width: 100%;
  transition: border-color .3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.i-product-variant.--open {
  --border-color: #eef1f3;
  --background-color: #dadfe3;
  z-index: 3;
  padding-bottom: .5rem;
}

.i-product-variant.--open:after {
  border-color: #0000;
}

.i-product-variant.--open .i-product-variant__body {
  grid-template-rows: 1fr;
}

.i-product-variant.--open .--js-open-variant svg {
  transform: scale(-1);
}

.i-step {
  --size: 9rem;
}

.i-step:before {
  content: "";
  background-color: #eef1f3;
  display: block;
  position: absolute;
}

@media (width <= 35.999em) {
  .i-step:before {
    display: none;
  }
}

.i-step.--column {
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.i-step.--column:before {
  left: 50%;
  top: calc(var(--size) / 2);
  width: calc(100% + 2rem);
  height: .25rem;
  transform: translateX(-50%);
}

.i-step.--inline:before {
  top: 0;
  left: calc(var(--size) / 2);
  width: .25rem;
  height: calc(100% + 4.5rem);
}

@media (width <= 35.999em) {
  .i-step.--inline {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}

.i-step__icon {
  width: var(--size);
  height: var(--size);
  background-color: #eef1f3;
  border-radius: 50%;
}

.i-step__count {
  color: #363738;
  font-size: 3.5rem;
  bottom: 0;
  left: 0;
}

.i-step__count:after {
  content: ".";
}

.i-tag {
  --padding-x: 1rem;
  --padding-y: .125rem;
  --min-height: 2rem;
  --font-size: 1rem;
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  min-height: var(--min-height);
  font-size: var(--font-size);
}

.i-tag.--sm {
  --padding-x: .5rem;
  --padding-y: 0;
  --min-height: 1.5rem;
  --font-size: .875rem;
}

.i-tag.--sm-base {
  --padding-x: .5rem;
  --padding-y: 0;
  --min-height: 1.5rem;
  --font-size: 1rem;
}

.i-tag.--lg {
  --padding-x: 1.5rem;
  --padding-y: .25rem;
  --min-height: 2.5rem;
}

.i-tag {
  --font-color: #363738;
  --background-color: #eef1f3;
  background-color: var(--background-color);
  color: var(--font-color);
  border: .0625rem solid var(--background-color);
  border-radius: 0;
}

.i-tag.--outline {
  --background-color: #fff;
  border-color: #dadfe3;
}

.i-tag.--outline.--hoverable:hover {
  --background-color: #fff;
}

.i-tag.--blank {
  --background-color: transparent;
  min-height: auto;
  padding: 0;
}

.i-tag.--product {
  --font-color: #fff;
  --background-color: #00c752;
}

.i-tag.--code {
  --font-color: #008443;
  --background-color: #eef1f3;
  --padding-x: 1rem;
  font-weight: 700;
}

.i-tag.--radius {
  border-radius: 6.25rem;
}

.i-tag.--icon-before .i-tag__icon {
  order: -1;
}

.i-tag.--icon-only {
  width: var(--min-height);
  height: var(--min-height);
  min-height: auto;
  padding: 0;
}

.i-tag.--icon-only .i-tag__title {
  display: none;
}

a.i-tag, .i-tag.--hoverable {
  cursor: pointer;
  text-decoration: none;
  transition: background-color .3s, border-color .3s;
}

a.i-tag:hover, .i-tag.--hoverable:hover {
  --background-color: #dadfe3;
}

a.i-tag.--blank {
  text-decoration: underline #0000;
  transition: text-decoration-color .3s;
}

a.i-tag.--blank:hover {
  -webkit-text-decoration-color: var(--font-color);
  text-decoration-color: var(--font-color);
  border-color: #0000;
}

.i-testimonial {
  --blend-mode: darken;
  --grayscale: 1;
  --width: 8.5rem;
  --height: 3rem;
  width: var(--width);
  max-width: var(--width);
  height: var(--height);
  justify-self: center;
}

.i-testimonial .i-testimonial__image {
  mix-blend-mode: var(--blend-mode);
  filter: grayscale(var(--grayscale));
  will-change: mix-blend-mode, filter;
  transition: mix-blend-mode .3s, filter .3s;
}

.i-testimonial:hover {
  --blend-mode: normal;
  --grayscale: 0;
}

.i-tooltip__content {
  filter: drop-shadow(0 .0625rem .25rem #0f1c7b9e);
  background-color: #fff;
  max-width: 7.625rem;
}

.i-tooltip__content:before {
  content: "";
  background-color: #fff;
  width: .75rem;
  height: .75rem;
  display: block;
  position: absolute;
  top: -.25rem;
  left: 1.125rem;
  transform: rotate(45deg);
}

.i-usp__icon {
  width: 2.5rem;
  height: 2.5rem;
}

.c-language-select-mobile__drowpdown {
  grid-template-rows: 0fr;
  transition: grid-template-rows .5s;
}

.c-language-select-mobile__dropdown-link {
  color: #fff;
}

.c-language-select-mobile__dropdown-link:hover, .c-language-select-mobile__dropdown-link:focus {
  color: #f24f00;
}

.c-language-select-mobile {
  padding: .625rem;
}

.c-language-select-mobile svg {
  will-change: transform;
  transition: transform .3s;
}

.c-language-select-mobile.--open .c-language-select-mobile__drowpdown {
  grid-template-rows: 1fr;
}

.c-language-select-mobile.--open .c-language-select-mobile__active svg {
  transform: rotate(90deg);
}

.c-language-select {
  --dropdown-opacity: 0;
  --dropdown-pointer-events: none;
}

.c-language-select:before {
  content: "";
  background-color: #dadfe3;
  width: .0625rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: -2rem;
  transform: translateX(-50%);
}

.c-language-select:hover {
  --dropdown-opacity: 1;
  --dropdown-pointer-events: auto;
}

.c-language-select__drowpdown {
  opacity: var(--dropdown-opacity);
  pointer-events: var(--dropdown-pointer-events);
  background-color: #fff;
  border: .0625rem solid #dadfe3;
  transition: opacity .3s;
  top: 100%;
  left: 0;
  box-shadow: 0 .5rem 1rem .125rem #97979766;
}

.c-language-select__active {
  min-height: 2.5rem;
}

.c-language-select__dropdown-link {
  color: #363738;
  text-decoration: none;
}

.c-language-select__dropdown-link:hover {
  color: #f24f00;
}

.link.--animation-right svg {
  transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
}

.link.--animation-right:hover svg {
  transform: translateX(.5rem);
}

.link.--animation-zoom:hover svg {
  transform: scale(1.1);
}

.link.--black {
  --color: #081828;
  color: var(--color);
  -webkit-text-decoration-color: var(--color);
  text-decoration-color: var(--color);
}

.link.--black:hover, .link.--black:focus {
  color: var(--color);
  text-decoration-color: #0000;
}

.link.--primary-to-conversion {
  --color: #363738;
  color: var(--color);
  text-decoration-color: #0000;
}

.link.--primary-to-conversion:hover, .link.--primary-to-conversion:focus, .link.--primary {
  --color: #f24f00;
  color: var(--color);
  text-decoration-color: #0000;
}

.link.--primary:hover, .link.--primary:focus {
  --color: #363738;
  color: var(--color);
  -webkit-text-decoration-color: var(--color);
  text-decoration-color: var(--color);
  text-decoration-thickness: .125rem;
}

.link.--primary:hover path, .link.--primary:focus path {
  stroke: var(--color-hover);
}

.link.--primary-only, .link.--primary-only:hover, .link.--primary-only:focus {
  --color: #363738;
  color: var(--color);
  text-decoration-color: #0000;
}

.link__icon svg {
  will-change: transform;
  transition: transform .3s;
}

.link {
  --text-decor-offset: .125rem;
  --text-decor-thickness: .125rem;
  text-underline-offset: var(--text-decor-offset);
  text-decoration: underline #0000;
  text-decoration-thickness: var(--text-decor-thickness);
  transition: text-decoration-color .3s, color .3s;
}

.link:hover {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.link.--icon-before .link__icon {
  order: -1;
}

.link.--underline-initial {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.link.--underline-initial:hover, .link.--underline-initial:focus {
  text-decoration-color: #0000;
}

.main-footer-column:nth-child(2) .main-footer-column__content {
  padding-right: 1.5rem;
}

@media (width <= 61.999em) {
  .main-footer-column:nth-child(2) .main-footer-column__content {
    padding-right: 0;
  }
}

@media (width <= 35.999em) {
  .main-footer-column {
    text-align: center;
    align-items: center;
  }
}

.main-footer-column__content.entry-content p:last-child {
  margin-bottom: 0;
}

.main-footer-column__logo {
  max-width: 18.875rem;
  max-height: 7.5rem;
}

.main-footer__email {
  word-break: break-all;
}

.main-footer__email .link__icon {
  flex-shrink: 0;
}

@media (width >= 36em) {
  .main-footer__columns {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media (width >= 75em) {
  .main-footer__columns {
    grid-template-columns: repeat(4, 1fr);
  }
}

.main-footer:before {
  content: "";
  background-color: #dadfe3;
  width: 100%;
  max-width: 98rem;
  height: .0625rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.main-footer__top {
  padding-block: 5rem;
}

@media (width <= 35.999em) {
  .main-footer__top {
    padding-block: 2.5rem;
  }
}

.main-footer__strip {
  background: #00c752;
  width: 100%;
  max-width: 100%;
  height: 1rem;
}

@media (width <= 35.999em) {
  .m-header-logo {
    max-width: 7.5rem;
  }
}

.m-header-search__input .f-text__input {
  padding-right: 2.5rem;
}

.m-header-search__submit {
  margin-left: -2.5rem;
}

.m-header {
  --min-height: 5rem;
  --padding-y: 1rem;
  --padding-x: 2rem;
  z-index: 5;
  background-color: #fff;
  transition: transform .3s;
  position: sticky;
  top: 0;
  left: 0;
}

.m-header.headroom--unpinned {
  transform: translateY(calc(-100% - .0625rem));
}

.m-header__body {
  min-height: var(--min-height);
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
}

.m-header__body:after {
  content: "";
  background-color: #dadfe3;
  width: 100%;
  max-width: 100%;
  height: .0625rem;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
}

@media (width <= 61.999em) {
  .m-header__body {
    --padding-x: 1.5rem;
  }
}

@media (width <= 35.999em) {
  .m-header__body {
    --min-height: 4.5rem;
    --padding-x: 0;
  }
}

@media (width >= 62em) {
  .m-header__side-menu {
    gap: 4rem;
  }
}

.m-nav {
  --transition-timing: 0;
}

@media (width <= 61.999em) {
  .m-nav {
    transition: transform var(--transition-timing) ease;
    z-index: 100;
    background-color: #fff;
    width: 100%;
    max-width: 26.25rem;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    overflow: hidden auto;
    transform: translateX(100%);
  }

  .m-nav.--open {
    transform: translateX(0);
  }

  .m-nav.--transition {
    --transition-timing: .3s;
  }
}

.m-nav__header:after {
  content: "";
  background-color: #dadfe3;
  width: 100%;
  max-width: 100%;
  height: .0625rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (width >= 62em) {
  .m-nav__wrap:after {
    content: "";
    background-color: #dadfe3;
    width: 100%;
    max-width: 100%;
    height: .0625rem;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media (width <= 93.749em) {
  .m-nav__list {
    flex-direction: column;
  }
}

@media (width <= 61.999em) {
  .m-nav__list {
    flex-grow: 1;
    width: 100%;
  }
}

@media (width >= 62em) {
  .m-nav__list {
    flex-direction: row;
    align-items: start;
  }
}

@media (width <= 93.749em) {
  .m-nav__catalog-btn {
    margin-left: auto;
  }
}

@media (width <= 61.999em) {
  .m-nav__catalog-btn {
    width: 100%;
    margin-left: 0;
  }

  .m-nav__catalog-btn .btn__icon {
    display: none;
  }
}

@media (width >= 62em) and (width <= 74.999em) {
  .m-nav__inner {
    gap: .5rem;
  }
}

.c-modal__dialog {
  pointer-events: none;
  cursor: cell;
  width: auto;
  margin: 2rem auto;
  position: relative;
}

.c-modal__content {
  width: var(--width);
  pointer-events: auto;
  cursor: auto;
  transition: transform var(--transtion-length) ease;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  margin-inline: auto;
  position: relative;
  transform: translateY(2rem);
  box-shadow: .25rem .25rem 1.5rem #0f26391a;
}

.c-modal__body {
  flex: auto;
  padding: 2rem 1.5rem;
  position: relative;
}

.c-modal__header {
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1.5rem 0;
  display: flex;
}

.c-modal__button-container {
  order: -1;
  justify-content: flex-end;
  display: flex;
}

.c-modal__close-cross {
  transform: translate(-1rem, 1rem);
}

.c-modal__footer {
  padding: 0 1.5rem 2rem;
}

.c-modal__footer.--right {
  justify-content: flex-end;
}

.c-modal__footer.--center {
  justify-content: center;
}

.c-modal__footer.--between {
  justify-content: space-between;
}

.c-modal__heading {
  font-size: 1.375rem;
}

.c-modal {
  --transtion-length: .3s;
  --width: min(100% - 2rem, 45rem);
  z-index: 9000;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  transition: opacity var(--transtion-length) ease;
  background-color: #00000040;
  outline: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.c-modal.--open {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.c-modal.--open .c-modal__content {
  transform: translateY(0);
}

.c-modal.--hidden {
  visibility: hidden;
}

.c-modal.--size-sm {
  --width: min(100% - 2rem, 30rem);
}

.c-modal.--size-xl {
  --width: min(100% - 2rem, 71.25rem);
}

.c-overlay {
  --opacity: 0;
  --pointer-events: none;
  --position: fixed;
  z-index: 1;
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  position: var(--position);
  background-color: #081828;
  transition: opacity .3s;
  inset: 0;
}

.c-overlay.--show {
  --opacity: .75;
  --pointer-events: auto;
}

.c-overlay.--absolute {
  --position: absolute;
  width: 100%;
  height: 100%;
}

.link.c-pagination__link {
  --background: transparent;
  --size: 2.5rem;
  background-color: var(--background);
  min-height: var(--size);
  border-radius: 0;
  padding-block: .5rem;
  transition-property: text-decoration-color, color, background-color;
}

.link.c-pagination__link.--prev, .link.c-pagination__link.--next {
  text-transform: uppercase;
}

.link.c-pagination__link.--page-number {
  min-width: var(--size);
  padding: .5rem;
  text-decoration: none;
}

.link.c-pagination__link.--page-number:hover {
  --background: #eef1f3;
}

.link.c-pagination__link.--active {
  --background: transparent;
  pointer-events: none;
  color: #f24f00;
}

.link.c-pagination__link.--blank {
  pointer-events: none;
}

@media (width <= 47.999em) {
  .c-pagination__items {
    flex-basis: 100%;
    order: -1;
    justify-content: center;
  }

  .c-pagination {
    justify-content: center;
  }
}

.pd-variants-table {
  overscroll-behavior: contain;
}

.pd-variants-table__header {
  transition: top .3s;
}

.pd-variants-table__scroller {
  min-height: 4rem;
  overflow: hidden;
}

.pd-variants-table__header-item {
  flex: 0 0 8.375rem;
  min-width: 8.375rem;
}

.pd-variants-table__header-item:after {
  content: "";
  background-color: #63666a;
  width: .0625rem;
  height: 3rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

.pd-variants-table__items {
  scroll-snap-type: x mandatory;
  overflow: auto;
}

.pd-variants-table__items.--hidden, .pd-variants-table__empty.--hidden {
  display: none;
}

.c-scroll-up {
  --size: 2.5rem;
  --offset: 2.5rem;
  --radius: 0;
  --background: #fff;
  --background-hover: #fa6900;
  --icon-size: 1rem;
  --icon-color: #f24f00;
  --border-color: #f24f00;
  width: var(--size);
  height: var(--size);
  bottom: var(--offset);
  right: var(--offset);
  border-radius: var(--radius);
  border: .0625rem solid var(--border-color);
  background-color: var(--background);
  z-index: 50;
  will-change: opacity, transform, background-color, border-color;
  pointer-events: none;
  opacity: 0;
  transform: translateY(var(--offset)) scale(.8);
  cursor: pointer;
  transition: background-color .3s, opacity .3s cubic-bezier(.68, -.55, .27, 1.55), transform .3s cubic-bezier(.68, -.55, .27, 1.55), border-color .3s;
}

.c-scroll-up:hover {
  --background: var(--background-hover);
  --border-color: #fa6900;
  --icon-color: #fff;
}

.c-scroll-up:after {
  content: "";
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity .5s, box-shadow .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 .625rem 2.5rem #fff;
}

.c-scroll-up:active:after {
  opacity: 1;
  transition: all;
  position: absolute;
  box-shadow: 0 0 #fff;
}

.c-scroll-up svg {
  width: var(--icon-size);
  height: var(--icon-size);
}

.c-scroll-up path {
  stroke: var(--icon-color);
  transition: stroke .3s;
}

.c-scroll-up.--active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0)scale(1);
}

.s-breadcrumbs__homepage {
  display: block;
}

.s-calendar__app {
  --fc-border-color: #ebebeb;
  --fc-today-bg-color: #eef1f3;
  --fc-event-bg-color: #363738;
  --fc-button-bg-color: #363738;
  --fc-button-border-color: #363738;
  --fc-button-active-bg-color: #476cfe;
  --fc-button-active-border-color: #476cfe;
  --fc-button-hover-bg-color: #476cfe;
  --fc-button-hover-border-color: #476cfe;
  height: 35.625rem;
}

.s-calendar__app.fc {
  height: auto;
}

.s-calendar__app.--close {
  display: none;
}

.s-calendar__app .fc-daygrid {
  background-color: #fff;
}

.s-calendar__app .fc-event {
  border: none;
  border-radius: 0;
}

.s-calendar__app .fc-daygrid-day-number {
  color: #363738;
  text-decoration: none;
}

.s-calendar__app .fc-event-time {
  flex-shrink: 0;
}

.s-calendar__app .fc-col-header-cell-cushion {
  text-decoration: none;
}

.s-calendar__app .fc-col-header-cell {
  padding: .5rem;
}

.s-calendar__toggler.--close svg {
  transform: scale(-1);
}

.s-error-404__heading {
  --font-size: 11rem;
  font-size: var(--font-size);
  line-height: 1;
}

@media (width <= 47.999em) {
  .s-error-404__heading {
    --font-size: 8.5rem;
  }
}

.s-error-404__sub-heading {
  margin-inline: auto;
  max-width: 45rem;
  margin-bottom: 1.5rem;
}

.s-error-404__perex {
  text-align: center;
  margin-inline: auto;
  max-width: 45rem;
  margin-bottom: 0;
}

.s-jobs__footer {
  background-color: #eef1f3;
}

@media (width >= 48em) {
  .s-jobs__footer {
    padding: 3rem 4rem;
  }
}

@media (width >= 62em) {
  .s-post-detail__meta {
    column-gap: 5rem;
  }
}

@media (width <= 35.999em) {
  .s-post-detail__share-links {
    width: 100%;
  }
}

.s-post-detail__share:before {
  top: 0;
  transform: translateY(-50%);
}

.s-post-detail__share:after {
  bottom: 0;
  transform: translateY(50%);
}

.s-post-detail__share:before, .s-post-detail__share:after {
  content: "";
  background-color: #dadfe3;
  width: 100%;
  height: .0625rem;
  position: absolute;
  left: 0;
}

.s-posts-grid__load-more {
  margin-top: 2.5rem;
}

@media (width <= 47.999em) {
  .s-posts-grid__load-more {
    margin-top: 2rem;
  }
}

@media (width >= 48em) {
  .s-posts-list__pagination {
    margin-top: 4.5rem;
  }
}

.s-product-list__empty.--hidden {
  display: none;
}

.s-products-slider {
  overflow: hidden;
}

@media (width <= 47.999em) {
  .s-products-slider__slider {
    width: min(100%, 20.5rem);
    margin-inline: auto;
  }
}

.s-text-media__grid {
  flex-direction: column;
  gap: 4rem;
  display: flex;
}

@media (width >= 62em) {
  .s-text-media__grid {
    grid-template-columns: 24.75rem 1fr;
    display: grid;
  }
}

@media (width >= 75em) {
  .s-text-media__grid {
    grid-template-columns: 28rem 1fr;
  }
}

@media (width >= 93.75em) {
  .s-text-media__grid {
    grid-template-columns: 35.5rem 1fr;
  }
}

@media (width <= 61.999em) {
  .s-text-media__grid {
    gap: 3rem;
  }
}

@media (width <= 47.999em) {
  .s-text-media__grid {
    gap: 1.5rem;
  }
}

.s-text-media__col.--content-col {
  order: var(--content-order);
  padding-left: var(--content-offset-left);
  padding-right: var(--content-offset-right);
}

.s-text-media {
  --content-offset-left: 0;
  --content-offset-right: 0;
  --content-order: 1;
}

@media (width >= 62em) {
  .s-text-media.--reverse .s-text-media__grid {
    --content-order: -1;
    grid-template-columns: 1fr 24.75rem;
  }
}

@media (width >= 75em) {
  .s-text-media.--reverse .s-text-media__grid {
    grid-template-columns: 1fr 28rem;
  }
}

@media (width >= 93.75em) {
  .s-text-media.--reverse .s-text-media__grid {
    grid-template-columns: 1fr 35.5rem;
  }

  .s-text-media.--product .s-text-media__grid {
    grid-template-columns: 41rem 1fr;
  }
}

@media (width <= 93.749em) {
  .s-text-media.--product .s-text-media__grid {
    gap: 3rem;
  }
}

@media (width >= 62em) and (width <= 74.999em) {
  .s-text-media.--product .s-text-media__grid {
    gap: 2rem;
  }
}

@media (width <= 61.999em) {
  .s-text-media.--product .s-text-media__col.--media-col {
    max-width: 41rem;
    margin-inline: auto;
  }
}

.s-text-media.--product .s-text-media__col.--content-col {
  justify-content: flex-start;
  padding-inline: .5rem;
}

@media (width >= 62em) {
  .s-text-media.--product .s-text-media__col.--content-col {
    padding-inline: 1rem;
  }
}

@media (width >= 93.75em) {
  .s-text-media.--product .s-text-media__col.--content-col {
    padding-inline: 2rem;
  }
}

.s-text-media.--product .s-text-media__meta-label {
  min-width: 5.5rem;
}

.s-text-media.--product .s-text-media__specs:before {
  content: "";
  pointer-events: none;
  background-color: #eef1f3;
  width: 100%;
  height: .0625rem;
  position: absolute;
  top: -1.5rem;
  left: 0;
  transform: translateY(-50%);
}

@media (width >= 62em) {
  .s-variant-intro__grid {
    grid-template-columns: 24.75rem 1fr;
    display: grid;
  }
}

@media (width >= 75em) {
  .s-variant-intro__grid {
    grid-template-columns: 28rem 1fr;
  }
}

@media (width >= 93.75em) {
  .s-variant-intro__grid {
    grid-template-columns: 29.875rem 1fr;
  }
}

.s-variant-intro__meta-label {
  min-width: 5.5rem;
}

.s-variant-intro__specs:before {
  content: "";
  pointer-events: none;
  background-color: #eef1f3;
  width: 100%;
  height: .0625rem;
  position: absolute;
  top: -1.5rem;
  left: 0;
  transform: translateY(-50%);
}

@media (width <= 61.999em) {
  .s-variant-intro__gallery-slider {
    max-width: 24.75rem;
    margin-inline: auto;
  }
}

@media (width <= 35.999em) {
  .s-variant-intro__gallery-slider {
    max-width: 100%;
  }
}

@media (width <= 47.999em) {
  .s-variant-intro__content-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .s-variant-intro__content-header .--js-pdf-button {
    margin-left: auto;
  }
}

@media (width >= 62em) and (width <= 74.999em) {
  .s-variant-intro__content-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .s-variant-intro__content-header .--js-pdf-button {
    margin-left: auto;
  }
}

.w-banner__image-link {
  text-decoration: none;
}

.w-banner__image-link .img {
  border: .0625rem solid #eef1f3;
}

.w-categories__item-link {
  color: var(--color);
  background-color: var(--background-color);
  border: .0625rem solid #eef1f3;
  min-height: 3.5rem;
  text-decoration: none;
  transition: color .3s, background-color .3s;
}

.w-categories__item-link:hover {
  --background-color: #eef1f3;
}

.w-categories__item {
  --background-color: transparent;
  --color: #363738;
}

.w-categories__item.--active {
  --background-color: transparent;
  --color: #f24f00;
}

.w-categories__item.--active .w-categories__item-link:hover, .w-categories__item.--active .w-categories__item-link:focus {
  --color: #f24f00;
  color: var(--color);
}

.widget_categories select {
  --border-color: #a9a9b2;
  --radius: .25rem;
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  width: 100%;
  height: 3rem;
  margin: 0;
  padding: .5rem;
}

.wp-aside-widgets .cat-item {
  align-items: center;
  display: flex;
}

.wp-aside-widgets .cat-item > a {
  width: 100%;
  font-weight: 600;
}

.widget_media_image a {
  backface-visibility: hidden;
  display: block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.widget_media_image a img {
  will-change: transform, opacity;
  transition-property: opacity, transform;
}

.widget_media_image a:hover img {
  transform: scale(1.03);
}

.widget_nav_menu .menu-item-has-children {
  --submenu-display: none;
  --svg-rotate: 0deg;
  position: relative;
}

.widget_nav_menu .menu-item-has-children .sub-menu {
  padding-left: 1rem;
}

.widget_nav_menu .menu-item-has-children > .sub-menu {
  display: var(--submenu-display);
}

.widget_nav_menu .menu-item-has-children > a {
  padding-right: 3rem;
}

.widget_nav_menu .menu-item-has-children.--open {
  --submenu-display: block;
  --svg-rotate: 180deg;
}

.widget_pages .page_item.active {
  text-decoration: underline;
}

.widget_pages .page_item_has_children {
  --submenu-display: none;
  --svg-rotate: 0deg;
  position: relative;
}

.widget_pages .page_item_has_children .sub-menu {
  padding-left: 1rem;
}

.widget_pages .page_item_has_children > .children {
  display: var(--submenu-display);
}

.widget_pages .page_item_has_children > a {
  padding-right: 3rem;
}

.widget_pages .page_item_has_children.--open {
  --submenu-display: block;
  --svg-rotate: 180deg;
}

.widget_search form > div {
  gap: .5rem;
  display: flex;
}

.widget_search input[type="text"] {
  --border-color: #a9a9b2;
  --radius: .25rem;
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  width: 100%;
  min-height: 2.875rem;
  padding-left: .5rem;
  display: block;
}

.widget_search input[type="text"]:focus {
  border-color: none;
  outline: none;
}

.widget_search input[type="submit"] {
  --border-radius: .375rem;
  --background: #363738;
  --font-color: #fff;
  color: var(--font-color);
  background-color: var(--background);
  border-radius: var(--border-radius);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  touch-action: manipulation;
  border: none;
  height: 100%;
  min-height: 2.875rem;
  padding-block: .5rem;
  padding-inline: 1rem;
  text-decoration: none;
  transition: background-color .3s;
  display: block;
}

.widget_search input[type="submit"]:hover {
  color: var(--font-color);
  text-decoration: none;
}

.widget_search input[type="submit"]:focus, .widget_search input[type="submit"]:active {
  color: var(--font-color);
  outline: 0;
  text-decoration: none;
}

@media (hover: hover) {
  .widget_search input[type="submit"]:hover {
    --background: #476cfe;
  }
}

.wp-aside-widgets ul {
  flex-direction: column;
  display: flex;
}

.wp-aside-widgets .widget {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

@media (width <= 61.999em) {
  .wp-aside-widgets .widget {
    --content-display: none;
    --svg-rotate: 0deg;
  }

  .wp-aside-widgets .widget ul, .wp-aside-widgets .widget form {
    display: var(--content-display);
  }

  .wp-aside-widgets .widget.--open {
    --content-display: flex;
    --svg-rotate: 180deg;
  }
}

.wp-aside-widgets .widgettitle {
  --size: 1.375rem;
  font-size: var(--size);
  line-height: 1.5rem;
  display: block;
  position: relative;
}

@media (width <= 47.999em) {
  .wp-aside-widgets .widgettitle {
    --size: 1.25rem;
  }
}

.wp-aside-widgets .wp-caption {
  flex-direction: column;
  gap: .5rem;
  max-width: 100%;
  margin: 0;
  display: flex;
}

.wp-aside-widgets li {
  --color: #363738;
  display: block;
}

.wp-aside-widgets li > a {
  color: var(--color);
  padding-block: 1rem;
  background-color: #0000;
  border: .0625rem solid #eef1f3;
  align-items: center;
  min-height: 3.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-decoration-color: #0000;
  transition: color .3s, background-color .3s, text-decoration-color .3s;
  display: flex;
}

.wp-aside-widgets li > a:hover {
  background-color: #eef1f3;
  text-decoration-color: #0000;
}

.widget__dropdown-btn {
  --size: 1.5rem;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.widget__dropdown-btn svg {
  rotate: var(--svg-rotate);
  transition: rotate .3s;
}

@media (width >= 62em) {
  .widget__dropdown-btn {
    display: none;
  }
}

.wp-aside-widgets .sub-menu__button {
  --size: 2.5rem;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.wp-aside-widgets .sub-menu__button svg {
  rotate: var(--svg-rotate);
  transition: rotate .3s;
}

.wp-breadcrumbs {
  --gap: 1.5rem;
}

.wp-breadcrumbs__group > span {
  gap: var(--gap);
  display: flex;
}

@media (width <= 47.999em) {
  .wp-breadcrumbs__group {
    display: none;
  }
}

.wp-breadcrumbs__group > span span {
  --height: 3.5rem;
  min-height: var(--height);
  align-items: center;
  display: flex;
}

.wp-breadcrumbs__group > span span a {
  display: block;
}

.wp-breadcrumbs__group > span span:first-child a {
  color: #0000;
  height: var(--height);
  -webkit-user-select: none;
  user-select: none;
  background-image: url("home.66a7d664.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem;
  width: 1.5rem;
  display: inline-block;
  position: relative;
}

.wp-breadcrumbs__group > span span.breadcrumb_last {
  font-weight: 600;
}

.wp-breadcrumbs__group > span span:not(:first-child) {
  position: relative;
}

.wp-breadcrumbs__group > span span:not(:first-child):before {
  content: "";
  left: calc(var(--gap) / -2);
  background-color: #363738;
  width: .25rem;
  height: .25rem;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.entry-content > * + * {
  margin-block-start: 1rem;
}

.entry-content strong:empty, .entry-content b:empty, .entry-content p:empty {
  display: none;
}

.entry-content hr {
  background: #ebebeb;
  border: none;
  height: .0625rem;
  margin-block: 3rem;
}

.entry-content .wp-caption {
  text-align: center;
  border: 0;
  max-width: 100%;
  padding: 0;
}

.entry-content .wp-caption-text {
  text-align: center;
  margin-bottom: 0;
  margin-left: 0;
  padding: .25rem .625rem;
  font-size: 1rem;
  transform: none;
}

.entry-content blockquote {
  background-color: #eef1f3;
  flex-direction: column;
  gap: .5rem;
  margin-block: 2rem;
  padding: 1rem;
  font-style: italic;
  font-weight: 400;
  display: flex;
  position: relative;
}

.entry-content blockquote p {
  margin-bottom: 0;
  font-style: italic;
}

.entry-content .column {
  width: 100%;
  min-height: .0625rem;
  margin-bottom: 1.5rem;
  padding-left: .9375rem;
  padding-right: .9375rem;
  position: relative;
}

.entry-content .column img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  float: none !important;
}

@media (width <= 61.999em) {
  .entry-content .column h2:first-of-type, .entry-content .column h3:first-of-type, .entry-content .column h4:first-of-type, .entry-content .column h5:first-of-type, .entry-content .column h6:first-of-type {
    margin-top: 0;
  }
}

.entry-content .columns-1, .entry-content .columns-1 .column {
  display: block;
}

.entry-content .columns-2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  display: grid;
}

@media (width <= 61.999em) {
  .entry-content .columns-2 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content .columns-3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  display: grid;
}

@media (width <= 61.999em) {
  .entry-content .columns-3 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content .columns-4 {
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  display: grid;
}

@media (width <= 74.999em) {
  .entry-content .columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 61.999em) {
  .entry-content .columns-4 {
    flex-direction: column;
    display: flex;
  }
}

@font-face {
  font-family: Roobert CEZ;
  src: url("RoobertCEZ-Regular.1090b2de.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: Roobert CEZ;
  src: url("RoobertCEZ-Medium.3cb7ddab.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: Roobert CEZ;
  src: url("RoobertCEZ-SemiBold.f25b138a.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: Roobert CEZ;
  src: url("RoobertCEZ-Bold.70ea2b35.woff") format("woff");
  font-weight: 700;
}

.entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6 {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.entry-content h1 {
  margin-top: 0;
  position: relative;
}

.entry-content h2 {
  position: relative;
}

.entry-content h1, .entry-content h2 {
  color: #008443;
}

.entry-content > h2:first-child, .entry-content > h3:first-child {
  margin-top: 0;
}

.entry-content > a {
  display: block;
}

.entry-content img {
  max-width: 100%;
  margin-block: 1rem;
}

.entry-content ol > li {
  counter-increment: item;
  padding-left: 1.5rem;
  position: relative;
}

.entry-content ol > li:before {
  content: counter(item) ".";
  color: #00c752;
  width: 1.75rem;
  height: 1.75rem;
  margin-left: -2.1875rem;
  margin-right: 0;
  padding: .0625rem .25rem .0625rem .375rem;
  font-size: 1rem;
  font-weight: 700;
  display: inline-flex;
  top: .0625rem;
}

.entry-content ol > li ol > li {
  counter-increment: sub-item;
}

.entry-content ol > li ol > li:before {
  content: counter(sub-item) ".";
}

.entry-content ol > li ol > li ol > li {
  counter-increment: sub-sub-item;
}

.entry-content ol > li ol > li ol > li:before {
  content: counter(sub-sub-item) ".";
}

.entry-content ol[style*="list-style-type: lower-alpha"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-alpha"] li:before {
  content: counter(item, lower-alpha) ")";
}

.entry-content ol[style*="list-style-type: upper-alpha"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-alpha"] li:before {
  content: counter(item, upper-alpha) ")";
}

.entry-content ol[style*="list-style-type: lower-latin"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-latin"] li:before {
  content: counter(item, lower-latin) ")";
}

.entry-content ol[style*="list-style-type: upper-latin"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-latin"] li:before {
  content: counter(item, upper-latin) ")";
}

.entry-content ol[style*="list-style-type: lower-greek"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-greek"] li:before {
  content: counter(item, lower-greek) ")";
}

.entry-content ol[style*="list-style-type: upper-greek"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-greek"] li:before {
  content: counter(item, upper-greek) ")";
}

.entry-content ol[style*="list-style-type: lower-roman"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-roman"] li:before {
  content: counter(item, lower-roman) ")";
}

.entry-content ol[style*="list-style-type: upper-roman"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-roman"] li:before {
  content: counter(item, upper-roman) ")";
}

.entry-content ul {
  list-style-type: none;
}

.entry-content ul > li {
  padding-left: 1rem;
  position: relative;
}

.entry-content ul > li:before {
  content: "";
  background-color: #00c752;
  border-radius: 50%;
  width: .5rem;
  height: .5rem;
  display: block;
  position: absolute;
  top: .5rem;
  left: 0;
}

.entry-content ul, .entry-content ol {
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;
}

.entry-content ul ul, .entry-content ul ol, .entry-content ol ul, .entry-content ol ol {
  margin-top: .5rem;
  margin-bottom: 0;
}

.entry-content ul li, .entry-content ol li {
  margin-bottom: .5rem;
}

.entry-content ul li:last-child, .entry-content ol li:last-child, p {
  margin-bottom: 0;
}

p a {
  display: inline;
}

.entry-content .table-responsive {
  border: 1rem solid #fff;
  margin: 1rem 0;
  overflow: auto;
  box-shadow: 0 0 0 .0625rem #ebebeb;
}

.entry-content th, .entry-content td, .entry-content tr {
  border: none !important;
}

.entry-content td, .entry-content th {
  text-align: center;
  height: 3rem;
  padding: .5rem 1rem;
  position: relative;
}

.entry-content tfoot td:before, .entry-content tfoot th:before {
  top: 0;
  bottom: auto;
}

.entry-content thead, .entry-content tfoot {
  color: #008443;
  background-color: #fff;
  font-size: 1rem;
  font-weight: 600;
}

.entry-content thead td:before, .entry-content thead th:before, .entry-content tfoot td:before, .entry-content tfoot th:before {
  content: "";
  background: #008443;
  width: 100%;
  height: .0625rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.entry-content tbody {
  color: #363738;
  background-color: #fff;
  font-size: 1rem;
}

.entry-content tbody tr:nth-child(2n) {
  background-color: #eef1f3;
}

.entry-content tbody td, .entry-content tbody th {
  height: 2.75rem;
  padding: .5rem 1rem;
}

.entry-content tbody td:before, .entry-content tbody th:before {
  content: "";
  background: #dadfe3;
  width: .0625rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.entry-content tbody td:last-child:before, .entry-content tbody th:last-child:before {
  display: none;
}

.entry-content table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0 !important;
}

.entry-content table p {
  font-size: 1rem;
  transform: none;
  margin-bottom: 0 !important;
}

.g-search-results {
  flex-direction: column;
  gap: 2rem;
  padding-top: 5rem;
  display: flex;
}

.i-search-result {
  max-width: 80ch;
}

.i-search-result__heading-link {
  text-decoration-color: #0000;
}

.i-search-result__heading-link:hover {
  text-decoration-color: #363738;
}

.i-search-result__link {
  text-decoration-color: #0000;
  transition-property: color, text-decoration-color;
}

.i-search-result__link:hover {
  color: #363738;
  text-decoration-color: #363738;
}

.entry-content .gallery-item {
  margin: 0;
  padding: 0;
  position: relative;
}

.entry-content .gallery-item a {
  transition: opacity .3s;
  display: block;
}

.entry-content .gallery-item a:hover {
  opacity: .7;
}

.entry-content .gallery-item img {
  object-fit: contain;
  height: 100%;
  margin: 0 auto;
}

.search-form__wrap {
  align-items: center;
  gap: 1rem 2rem;
  max-width: 80ch;
  display: flex;
  position: relative;
}

@media (width <= 61.999em) {
  .search-form__wrap {
    flex-wrap: wrap;
  }
}

.search-form__heading {
  flex-shrink: 0;
}

.search-form {
  background-color: #fff;
  flex-grow: 1;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 0 .0625rem #989ea3;
}

.search-form__input {
  color: #363738;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 1;
  padding: .5rem;
}

@media (width <= 35.999em) {
  .search-form__input {
    min-width: 0;
  }
}

.search-form__submit {
  cursor: pointer;
  background-color: #0000;
  border: none;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  transition: background-color .2s;
  display: flex;
}

.search-form__submit svg {
  width: 1.125rem;
  height: 1.125rem;
}

.search-form__submit:active, .search-form__submit:focus {
  outline: 0;
}

.entry-content .gallery {
  align-items: center;
  gap: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: grid;
}

.entry-content .gallery > br {
  display: none;
}

.entry-content .gallery-columns-9 {
  grid-template-columns: repeat(8, 1fr);
}

@media (width <= 61.999em) {
  .entry-content .gallery-columns-9 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-8 {
  grid-template-columns: repeat(8, 1fr);
}

@media (width <= 61.999em) {
  .entry-content .gallery-columns-8 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-7 {
  grid-template-columns: repeat(7, 1fr);
}

@media (width <= 61.999em) {
  .entry-content .gallery-columns-7 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-6 {
  grid-template-columns: repeat(6, 1fr);
}

@media (width <= 61.999em) {
  .entry-content .gallery-columns-6 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-5 {
  grid-template-columns: repeat(5, 1fr);
}

@media (width <= 47.999em) {
  .entry-content .gallery-columns-5 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-4 {
  grid-template-columns: repeat(4, 1fr);
}

@media (width <= 47.999em) {
  .entry-content .gallery-columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-3 {
  grid-template-columns: repeat(3, 1fr);
}

.entry-content .gallery-columns-2 {
  grid-template-columns: repeat(2, 1fr);
}

.entry-content .wp-gallery-no-gutter .gallery {
  gap: 0;
}
/*# sourceMappingURL=index.css.map */
