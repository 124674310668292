@use "settings/s-colors" as colors;

.pd-variants-table {
  overscroll-behavior: contain;
}

.pd-variants-table__header {
  transition: top 0.3s ease;
}

.pd-variants-table__scroller {
  min-height: 64px;
  overflow: hidden;
}

.pd-variants-table__header-item {
  min-width: 134px;
  flex: 0 0 134px;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 1px;
    height: 48px;
    background-color: colors.$primary-500;
  }
}

.pd-variants-table__items {
  overflow: auto;
  scroll-snap-type: x mandatory;

  &.--hidden {
    display: none;
  }
}

.pd-variants-table__empty {
  &.--hidden {
    display: none;
  }
}
