@use "settings/s-colors" as colors;

.icon {
  --size: 24px;

  color: colors.$black;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);

  svg {
    width: 100%;
    height: 100%;
  }

  &.--colored {
    color: var(--color);

    path {
      stroke: var(--color);
      transition: stroke 0.3s ease;
    }
  }

  &.--stroke-off {
    path {
      stroke: revert;
    }
  }

  &.--primary {
    --color: #{colors.$conversion-500};
    --color-hover: #{colors.$primary};
  }

  &.--white {
    --color: #{colors.$white};
  }

  &.--black {
    --color: #{colors.$black};
  }

  &.--danger {
    --color: #{colors.$danger};
  }

  &.--4xl {
    --size: 96px;
  }

  &.--xxxl {
    --size: 48px;
  }

  &.--xxl {
    --size: 40px;
  }

  &.--xl {
    --size: 32px;
  }

  &.--lg {
    --size: 24px;
  }

  &.--md {
    --size: 20px;
  }

  &.--sm {
    --size: 16px;
  }

  &.--xs {
    --size: 14px;
  }
}
