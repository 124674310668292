@use "settings/s-colors" as colors;

.i-category__image {
  width: var(--min-height);
}

.i-category {
  --background: #{colors.$white};
  --color: #{colors.$primary};
  --border-color: #{colors.$primary-200};
  --min-height: 56px;
  --opacity: 1;
  --pointer-events: all;

  text-decoration: none;
  background-color: var(--background);
  min-height: var(--min-height);
  transition: background-color 0.3s ease;
  transition-property: background-color, color, border-color, opacity;
  z-index: 1;
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border: 1px solid var(--border-color);
    z-index: 2;
  }

  @media (hover: hover) {
    &:hover {
      --color: #{colors.$conversion-500};
    }
  }

  &.--active {
    --background: #{colors.$primary};
    --color: #{colors.$white};
    --border-color: #{colors.$primary};
  }

  &.--disabled {
    --opacity: 0.4;
    --pointer-events: none;

    user-select: none;
  }

  &.--input.--radio {
    --min-height: 56px;

    .i-category__image {
      width: var(--min-height);
    }
  }
}

.i-category__title {
  color: var(--color);
  transition: color 0.3s ease;
}

.i-category__input {
  pointer-events: none;
  opacity: 0;
}

.i-category:has(.i-category__input:checked) {
  --background: #{colors.$primary};
  --color: #{colors.$white};
  --border-color: #{colors.$primary};
}
