@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.m-nav {
  --transition-timing: 0;

  @include media-breakpoint-down(lg) {
    position: fixed;
    right: 0;
    top: 0;
    background-color: colors.$white;
    width: 100%;
    max-width: 420px;
    transform: translateX(100%);
    transition: transform var(--transition-timing) ease;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    &.--open {
      transform: translateX(0);
    }

    &.--transition {
      --transition-timing: 0.3s;
    }
  }
}

.m-nav__header {
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 1px;
    background-color: colors.$primary-200;
  }
}

.m-nav__wrap {
  @include media-breakpoint-up(lg) {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 1px;
      background-color: colors.$primary-200;
    }
  }
}

.m-nav__list {
  @include media-breakpoint-down(xxl) {
    flex-direction: column;
  }

  @include media-breakpoint-down(lg) {
    width: 100%;
    flex-grow: 1;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: start;
  }
}

.m-nav__catalog-btn {
  @include media-breakpoint-down(xxl) {
    margin-left: auto;
  }

  @include media-breakpoint-down(lg) {
    width: 100%;
    margin-left: 0;

    .btn__icon {
      display: none;
    }
  }
}

.m-nav__inner {
  @include media-breakpoint-only(lg) {
    gap: 8px;
  }
}
