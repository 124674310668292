@use "settings/s-colors" as colors;

.i-product-variant__header {
  min-height: var(--min-height);
  grid-template-columns: repeat(10, minmax(126px, 1fr)) 40px;
}

.i-product-variant__body {
  padding: 0;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease;
}

.--js-open-variant {
  svg {
    transition: transform 0.3s ease;
  }
}

.i-product-variant__content {
  --padding: 40px;

  padding: var(--padding);

  @include media-breakpoint-down(md) {
    --padding: 24px;
  }
}

.i-product-variant {
  --border-color: transparent;
  --background-color: #{colors.$white};
  --min-height: 64px;

  min-width: 100%;
  width: fit-content;
  padding-bottom: 0;
  background-color: var(--background-color);
  transition:
    padding-bottom 0.3s ease,
    background-color 0.3s ease;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid var(--border-color);
    pointer-events: none;
    transition: border-color 0.3s ease;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid colors.$primary-200;
    pointer-events: none;
    transition: border-color 0.3s ease;
  }

  &.--open {
    --border-color: #{colors.$primary-100};
    --background-color: #{colors.$primary-200};

    padding-bottom: 8px;
    z-index: 3;

    &::after {
      border-color: transparent;
    }

    .i-product-variant__body {
      grid-template-rows: 1fr;
    }

    .--js-open-variant {
      svg {
        transform: scale(-1);
      }
    }
  }
}
