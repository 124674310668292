@use "settings/s-general.scss" as general;
@use "settings/s-colors" as colors;

.base-section {
  --padding-y: 80px;

  padding-block: var(--padding-y);

  @include media-breakpoint-down(sm) {
    --padding-y: 48px;
  }

  &.--bg-color {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 1568px;
      height: 100%;
      background-color: colors.$primary-100;
      z-index: -1;
    }
  }

  &.--top-0 {
    padding-top: 0;
  }

  &.--bottom-0 {
    padding-bottom: 0;
  }

  &.--md {
    --padding-y: 40px;
  }

  &.--sm {
    --padding-y: 24px;

    @include media-breakpoint-down(sm) {
      --padding-y: 16px;
    }
  }

  &.--top-sm-0 {
    --padding-y: 24px;

    padding-top: 0;

    @include media-breakpoint-down(sm) {
      --padding-y: 16px;
    }
  }

  &.--none {
    --padding-y: 0;
  }
}
