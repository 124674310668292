.g-product {
  &.--hidden {
    display: none;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-down(md) {
    max-width: 428px;
    margin-inline: auto;
  }
}
