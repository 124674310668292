.u-h1 {
  --size: 50px;
  --line-height: calc(var(--size) + 4px);

  @include media-breakpoint-down(md) {
    --size: 34px;
  }
}

.u-h2 {
  --size: 38px;
  --line-height: calc(var(--size) + 2px);

  @include media-breakpoint-down(md) {
    --size: 30px;
  }
}

.u-h3 {
  --size: 30px;
  --line-height: calc(var(--size) + 2px);

  @include media-breakpoint-down(md) {
    --size: 26px;
  }
}

.u-h4 {
  --size: 22px;
  --line-height: calc(var(--size) + 2px);

  @include media-breakpoint-down(md) {
    --size: 20px;
  }
}

.u-h5 {
  --size: 18px;
  --line-height: calc(var(--size) + 6px);

  @include media-breakpoint-down(md) {
    --size: 16px;
  }
}

.u-h1,
.u-h2,
.u-h3,
.u-h4,
.u-h5 {
  font-size: var(--size);
  line-height: var(--line-height);
  font-weight: 500;
}
