@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.m-header {
  --min-height: 80px;
  --padding-y: 16px;
  --padding-x: 32px;

  top: 0;
  left: 0;
  position: sticky;
  z-index: 5;
  background-color: colors.$white;
  transition: transform 0.3s ease;

  &.headroom--unpinned {
    transform: translateY(calc(-100% - 1px));
  }
}

.m-header__body {
  min-height: var(--min-height);
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    width: 100%;
    max-width: 100%;
    height: 1px;
    background-color: colors.$primary-200;
  }

  @include media-breakpoint-down(lg) {
    --padding-x: 24px;
  }

  @include media-breakpoint-down(sm) {
    --min-height: 72px;
    --padding-x: 0px;
  }
}

.m-header__side-menu {
  @include media-breakpoint-up(lg) {
    gap: 64px;
  }
}
