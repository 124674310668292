@use "settings/s-colors" as colors;

.f-checkbox-select__header {
  min-height: 40px;
  padding-block: 6px;
  border: 1px solid var(--border-color);
  user-select: none;
  background-color: var(--header-background);
}

.f-checkbox-select__clear {
  display: none;

  &.--active {
    display: inline-flex;
  }
}

.f-checkbox-select__toggler {
  width: 16px;
  height: 16px;

  svg {
    transition: scale 0.3s ease;
  }
}

.f-checkbox-select__title {
  height: 24px;

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    width: 16px;
    height: 100%;
    background: transparent linear-gradient(270deg, var(--header-background) 0%, #fff0 100%) 0% 0% no-repeat padding-box; /* stylelint-disable-line max-line-length */
    z-index: 1;
  }
}

.f-checkbox-select__body {
  border: 1px solid var(--border-color);
  top: calc(100% - 1px);
  left: 50%;
  transform: translateX(-50%) scaleY(0.25);
  transform-origin: top;
  width: 100%;
  background-color: colors.$white;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
  will-change: opacity, transform;
  margin-top: -1px;
  padding-right: 36px;

  .os-scrollbar-vertical {
    padding-bottom: 8px;
  }
}

.f-checkbox-select__body-inner {
  max-height: 338px;

  .f-checkbox:last-child {
    margin-bottom: 16px;
  }
}

.f-checkbox-select {
  --header-background: #{colors.$white};
  --border-color: #{colors.$primary-200};

  &.--inline {
    --opacity: 0;
    --height: 0;
    --pointer-events: none;

    .f-checkbox-select__body {
      height: var(--height);
      opacity: var(--opacity);
      pointer-events: var(--pointer-events);
      transition:
        height 0s ease,
        opacity 0s ease;
    }
  }

  &.--active {
    .f-checkbox-select__body {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(-50%) scaleY(1);
    }

    .f-checkbox-select__toggler {
      svg {
        scale: -1;
      }
    }

    &.--inline {
      --opacity: 1;
      --height: auto;
      --pointer-events: all;

      .f-checkbox-select__body {
        transition:
          height 0.3s ease,
          opacity 0.3s ease;
      }
    }
  }

  .f-label {
    margin-bottom: 8px;
  }
}
