@use "settings/s-colors" as colors;

.f-contact {
  --padding: 24px;
  --max-width: 100%;

  max-width: var(--max-width);
  padding: var(--padding);
  background: colors.$primary-100;

  @include media-breakpoint-up(md) {
    --padding: 40px;
  }

  @include media-breakpoint-down(sm) {
    --max-width: 488px;

    margin-inline: auto;
  }
}

.f-contact__grid {
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.f-contact__terms {
  a {
    font-weight: 400;
  }
}

.f-contact__submit {
  @include media-breakpoint-up(sm) {
    margin-left: auto;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
