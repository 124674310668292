.base-header {
  --spacing: 40px;

  margin-inline: auto;
  max-width: 928px;
  margin-bottom: var(--spacing);

  &:has(.base-heading.--decoration-after) {
    max-width: 100%;
  }

  &.--left {
    align-items: flex-start;
    text-align: left;
    margin-inline: 0;
  }

  &.--spacing-none {
    --spacing: 0;
  }

  &.--spacing-sm {
    --spacing: 24px;
  }

  &.--spacing-lg {
    --spacing: 80px;

    @include media-breakpoint-down(md) {
      --spacing: 40px;
    }
  }
}

.base-header__description {
  p {
    margin-bottom: 0;
  }
}
