.content-w-aside__content {
  @include media-breakpoint-up(xl) {
    grid-column: 1 / span 8;
  }

  @include media-breakpoint-down(xl) {
    > .s-breadcrumbs {
      display: none;
    }
  }
}

.content-w-aside__aside {
  @include media-breakpoint-up(xl) {
    grid-column: span 4;

    > .s-breadcrumbs {
      display: none;
    }
  }

  @include media-breakpoint-down(xl) {
    order: -1;
  }
}

.content-w-aside {
  --padding-y: 80px;

  padding-block: var(--padding-y);

  @include media-breakpoint-down(sm) {
    --padding-y: 48px;
  }

  &.--top-0 {
    padding-top: 0;
  }

  &.--none {
    --padding-y: 0;
  }

  &.--top-md {
    padding-top: 40px;

    &.content-w-aside-category {
      @include media-breakpoint-down(xl) {
        padding-top: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      padding-top: 24px;
    }
  }

  &.--reverse {
    .content-w-aside__aside {
      order: -1;
      grid-column: span 3;
    }

    .content-w-aside__content {
      grid-column: 4 / span 9;
    }
  }
}

.content-w-aside__row {
  --gap: 32px;

  gap: var(--gap);

  @include media-breakpoint-down(xl) {
    --gap: 80px;
  }

  @include media-breakpoint-down(sm) {
    --gap: 40px;
  }

  @include media-breakpoint-up(xl) {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(12, 1fr);
  }

  @include media-breakpoint-down(xl) {
    display: flex;
    flex-direction: column;
  }
}
