.g-hero-slider__button {
  --offset: 50%;

  pointer-events: auto;

  &.--prev {
    transform: translateX(calc(-1 * var(--offset)));
  }

  &.--next {
    transform: translateX(var(--offset));
  }

  @include media-breakpoint-down(sm) {
    --offset: 8px;
  }
}

.g-hero-slider__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;

  min-height: 8px;
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  gap: 8px;

  .swiper-pagination-bullet {
    transition: width 0.3s ease;
    transform-origin: center;
  }

  .swiper-pagination-bullet-active {
    --swiper-pagination-bullet-width: 32px;
  }
}

.g-hero-slider {
  .swiper-slide {
    flex-shrink: 0;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    width: 100% !important;
  }
}

.g-hero-slider__buttons {
  pointer-events: none;
}
