@use "settings/s-colors" as colors;

.i-author__description {
  p {
    margin-bottom: 0;
  }
}

.i-author {
  &.--compact {
    align-items: center;

    .i-author__description {
      display: none;
    }
  }
}

.i-author__image {
  --size: 40px;

  width: var(--size);
  height: var(--size);
  max-width: var(--size);

  .img {
    border-radius: 50%;
  }
}
