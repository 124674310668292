.i-gallery-slide__image {
  display: block;

  button {
    aspect-ratio: 1 / 1;
    background-color: none;
    border: none;
    outline: none;
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
  }

  a {
    display: block;
    outline: none;
    text-decoration: none;
  }
}

.i-gallery-slide__tag.i-tag {
  top: 8px;
  right: 8px;
  pointer-events: none;
}
