@use "settings/s-colors" as colors;

.btn {
  &.--primary {
    background-color: colors.$conversion-300;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 100%;
      pointer-events: none;
      background-color: colors.$primary;
      z-index: -1;
      transform-origin: 0 0;
      transform: skewX(45deg) translateX(100%);
      transition: 0.6s ease;
    }

    @media (hover: hover) {
      &:hover::before {
        transform: skewX(45deg) translateX(-50%);
      }
    }
  }

  &.--outline {
    --font-color: #{colors.$conversion-500};
    --border-color: #{colors.$conversion-500};

    background-color: colors.$white;
    border: 1px solid var(--border-color);
    overflow: hidden;
    transition: 0.6s ease;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 100%;
      pointer-events: none;
      background-color: colors.$conversion-300;
      z-index: -1;
      transform-origin: 0 0;
      transform: skewX(45deg) translateX(100%);
      transition: transform 0.6s;
    }

    @media (hover: hover) {
      &:hover {
        --font-color: #{colors.$white};
        --border-color: #{colors.$conversion-300};

        &::before {
          transform: skewX(45deg) translateX(-50%);
        }
      }
    }
  }

  &.--ghost {
    --background: transparent;
    --font-color: #{colors.$conversion-500};

    background-color: var(--background);
  }

  &.--facebook {
    --svg-size: 16px;
    --border-radius: 46px;
    --font-color: #{colors.$facebook};
    --border-color: #{colors.$facebook};
    --background: #{colors.$white};
    --text-transform: capitalize;

    gap: 16px;
    background-color: var(--background);
    border: 1px solid var(--border-color);
    transition: border-color 0.3s ease;
    transition-property: border-color, background-color, color;
    will-change: border-color, background-color, color;

    svg {
      margin-left: -1px;
    }

    path {
      fill: var(--font-color);
      transition: fill 0.3s ease;
    }

    @media (hover: hover) {
      &:hover {
        --font-color: #{colors.$conversion-300};
        --border-color: #{colors.$conversion-300};
        --background: #{colors.$white};
      }
    }
  }

  &.--twitter {
    --svg-size: 16px;
    --border-radius: 46px;
    --font-color: #{colors.$black};
    --border-color: #{colors.$black};
    --background: #{colors.$white};
    --text-transform: capitalize;

    gap: 16px;
    background-color: var(--background);
    border: 1px solid var(--border-color);
    transition: border-color 0.3s ease;
    transition-property: border-color, background-color, color;
    will-change: border-color, background-color, color;

    path {
      fill: var(--font-color);
      transition: fill 0.3s ease;
    }

    @media (hover: hover) {
      &:hover {
        --font-color: #{colors.$conversion-300};
        --border-color: #{colors.$conversion-300};
        --background: #{colors.$white};
      }
    }
  }

  &.--instagram {
    --svg-size: 16px;
    --border-radius: 46px;
    --font-color: #{colors.$instagram};
    --border-color: #{colors.$instagram};
    --background: #{colors.$white};
    --text-transform: capitalize;

    gap: 16px;
    background-color: var(--background);
    border: 1px solid var(--border-color);
    transition: border-color 0.3s ease;
    transition-property: border-color, background-color, color;
    will-change: border-color, background-color, color;

    path {
      fill: var(--font-color);
      transition: fill 0.3s ease;
    }

    @media (hover: hover) {
      &:hover {
        --font-color: #{colors.$conversion-300};
        --border-color: #{colors.$conversion-300};
        --background: #{colors.$white};
      }
    }
  }

  &.--linkedin {
    --svg-size: 16px;
    --border-radius: 46px;
    --font-color: #{colors.$linkedin};
    --border-color: #{colors.$linkedin};
    --background: #{colors.$white};
    --text-transform: capitalize;

    gap: 16px;
    background-color: var(--background);
    border: 1px solid var(--border-color);
    transition: border-color 0.3s ease;
    transition-property: border-color, background-color, color;
    will-change: border-color, background-color, color;

    path {
      fill: var(--font-color);
      transition: fill 0.3s ease;
    }

    @media (hover: hover) {
      &:hover {
        --font-color: #{colors.$conversion-300};
        --border-color: #{colors.$conversion-300};
        --background: #{colors.$white};
      }
    }
  }

  &.--youtube {
    --svg-size: 16px;
    --border-radius: 46px;
    --font-color: #{colors.$youtube};
    --border-color: #{colors.$youtube};
    --background: #{colors.$white};
    --text-transform: capitalize;

    gap: 16px;
    background-color: var(--background);
    border: 1px solid var(--border-color);
    transition: border-color 0.3s ease;
    transition-property: border-color, background-color, color;
    will-change: border-color, background-color, color;

    path {
      fill: var(--font-color);
      transition: fill 0.3s ease;
    }

    @media (hover: hover) {
      &:hover {
        --font-color: #{colors.$conversion-300};
        --border-color: #{colors.$conversion-300};
        --background: #{colors.$white};
      }
    }
  }
}
