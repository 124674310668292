@font-face {
  font-family: "Roobert CEZ";
  src: url("../fonts/RoobertCEZ-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Roobert CEZ";
  src: url("../fonts/RoobertCEZ-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Roobert CEZ";
  src: url("../fonts/RoobertCEZ-SemiBold.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Roobert CEZ";
  src: url("../fonts/RoobertCEZ-Bold.woff") format("woff");
  font-weight: 700;
}

$primary: "Roobert CEZ", sans-serif;
