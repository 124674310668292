@use "settings/s-colors.scss" as colors;

.u-bg-primary {
  background-color: colors.$primary;
}

.u-bg-primary-100 {
  background-color: colors.$primary-100;
}

.u-bg-primary-200 {
  background-color: colors.$primary-200;
}

.u-bg-gray {
  background-color: colors.$gray;
}

.u-bg-white {
  background-color: colors.$white;
}
