@use "settings/s-colors" as colors;

.f-textarea__input {
  color: var(--font-color);
  border-radius: var(--radius);
}

.f-textarea {
  --font-color: #{colors.$primary};
  --border-color: #{colors.$primary-300};
  --radius: 0;

  &.--invalid {
    --border-color: #{colors.$danger};
  }

  &:has(.f-textarea__input:focus) {
    --border-color: #{colors.$primary-500};
  }
}

.f-textarea__wrap {
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color 0.3s ease;
}
