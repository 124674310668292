@use "settings/s-colors" as colors;

.c-language-select-mobile__drowpdown {
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease;
}

.c-language-select-mobile__dropdown-link {
  color: colors.$white;

  &:hover,
  &:focus {
    color: colors.$conversion-500;
  }
}

.c-language-select-mobile {
  padding: 10px;

  svg {
    transition: transform 0.3s ease;
    will-change: transform;
  }

  &.--open {
    .c-language-select-mobile__drowpdown {
      grid-template-rows: 1fr;
    }

    .c-language-select-mobile__active svg {
      transform: rotate(90deg);
    }
  }
}
