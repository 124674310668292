.main-footer-column {
  &:nth-child(2) {
    .main-footer-column__content {
      padding-right: 24px;

      @include media-breakpoint-down(lg) {
        padding-right: 0;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    text-align: center;
    align-items: center;
  }
}

.main-footer-column__content.entry-content {
  p:last-child {
    margin-bottom: 0;
  }
}

.main-footer-column__logo {
  max-width: 302px;
  max-height: 120px;
}

.main-footer__email {
  word-break: break-all;

  .link__icon {
    flex-shrink: 0;
  }
}
