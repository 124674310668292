@use "settings/s-colors.scss" as colors;

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  text-wrap: balance;
  color: colors.$primary;
}
