@use "settings/s-colors.scss" as colors;

a {
  display: inline;
  font-weight: 500;
  color: colors.$conversion-500;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;
  transition-property: text-decoration-color, color;

  &:hover,
  &:focus {
    color: colors.$primary;
    text-decoration-color: colors.$primary;
  }
}
